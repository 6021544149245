import { BillableType, Product } from '~/app/shared';

export enum ForecastErrorType {
  Error,
  Warning
}

export type ForecastCellEmployee = {
  id: number;
  name: string;
  hiringDate: Date;
  endDate?: Date;
};

export type ForecastCell = {
  date: Date;
  employee: ForecastCellEmployee;
  product: Product;
  isSuccess?: boolean;
  billableType: BillableType;
  specialEffort?: number;
  comment?: string;
  errorMessage?: string;
  errorType?: ForecastErrorType;
  haveBilling: boolean;
};

export type ForecastForEmployeeList = {
  dates: string[];
  productId: number;
  billableType: BillableType;
  specialEffort?: number;
  employeeIds: number[];
};

export type ForecastCellSimplified = {
  date: string;
  employeeId: number;
  productId: number;
  billableType: BillableType;
  specialEffort?: number;
  comment?: string;
};

export type ForecastCellToChange = {
  date: Date;
  employeeId: number;
  productId?: number;
};

export const CELL_GUTTER = 0.2;
