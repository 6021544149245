import { differenceInBusinessDays } from 'date-fns';
import { ForecastCell } from './forecast-cell.model';
import { checkTwoCellsSameProduct } from './forecast.helper';
import { useForecastStore } from './forecast.store';

const COLUMNS_TO_CHECK = 50;

export function useForecastCell(timeline: ForecastCell[], columnIndex: number) {
  const [forecastState] = useForecastStore();
  const cell: ForecastCell = timeline[columnIndex];

  const commentsList = [cell?.comment];

  const prevCell = columnIndex > 0 ? timeline[columnIndex - 1] : null;
  const nextCell = columnIndex < timeline.length ? timeline[columnIndex + 1] : null;
  const isPreviousTheSame =
    prevCell?.date < forecastState?.visibleStartDay ? false : checkTwoCellsSameProduct(prevCell, cell);

  const isNextTheSame =
    nextCell?.date > forecastState?.visibleEndDay ? false : checkTwoCellsSameProduct(nextCell, cell);

  let maxPreviousTheSame = null;
  let maxNextTheSame = null;

  if (isPreviousTheSame) {
    const previousWeekCells: ForecastCell[] = timeline.slice(columnIndex - COLUMNS_TO_CHECK, columnIndex).reverse();
    const prevIndex = previousWeekCells.findIndex((c) => !checkTwoCellsSameProduct(c, cell));
    maxPreviousTheSame = prevIndex === -1 ? null : prevIndex;
  }

  if (isNextTheSame) {
    const nextWeekCells = timeline.slice(columnIndex + 1, columnIndex + COLUMNS_TO_CHECK);
    const allCellsOfBlockAreSameProductIncludingLast =
      nextWeekCells.filter((x) => checkTwoCellsSameProduct(x, cell))?.length === nextWeekCells.length;

    const extraIndexBecauseLastIsSame = allCellsOfBlockAreSameProductIncludingLast ? 1 : 0;

    const nextIndex = nextWeekCells.findIndex((c) => {
      const areSameProduct = checkTwoCellsSameProduct(c, cell);
      const isLastCell = differenceInBusinessDays(nextWeekCells[nextWeekCells.length - 1].date, c.date) < 1;

      if (areSameProduct || isLastCell) {
        commentsList.push(c?.comment);
      }

      return !areSameProduct || isLastCell;
    });

    maxNextTheSame = nextIndex === -1 ? null : nextIndex + extraIndexBecauseLastIsSame;
  }

  return {
    isPreviousTheSame,
    isNextTheSame,
    maxPreviousTheSame,
    maxNextTheSame,
    commentsList
  } as const;
}
