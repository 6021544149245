import './forecast-cell-comment-mark.css';
interface ForecastCellCommentMarkType {
  styles?: any;
  onHoverMark?: any;
  commentStyles: any;
  commentStateClass?: string;
  isSpecial?: boolean;
  commentsList: string[];
}

export function ForecastCellCommentMark(props: ForecastCellCommentMarkType) {
  const { styles, onHoverMark, isSpecial, commentsList, commentStyles, commentStateClass } = props;

  const defaultStyles = styles
    ? {
        width: styles.commentWidth || 'unset',
        top: styles.top,
        left: styles.left
      }
    : {};

  return (
    <div className={`forecast-cell-comment-mark no-gap ${commentStateClass}`} style={defaultStyles}>
      {commentsList.map((comment, i) => (
        <div
          style={commentStyles(i)}
          key={`comment-bar-${i}`}
          className="forecast-cell-comment-mark__content no-gap"
          onMouseEnter={() => {
            onHoverMark && onHoverMark(comment);
          }}
        >
          <span
            className={`forecast-cell-comment-mark__bar no-gap ${
              comment ? '' : 'forecast-cell-comment-mark__bar--transparent'
            } ${isSpecial ? 'forecast-cell-comment-mark__bar--special' : ''}`}
          ></span>
        </div>
      ))}
    </div>
  );
}
