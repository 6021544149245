import { HttpFetchResponse, MAX_SAFE_INTEGER_API, Team, Unit } from '~/app/shared';

async function getAllByPermission() {
  const url = new URL(process.env.REACT_APP_API_URL);
  url.pathname = '/unit/getAllByPermission';
  const response = await fetch(url);

  if (response.ok) {
    const responseBody = await response.json();

    return { data: responseBody.items, status: response.status };
  }

  return { data: null, status: response.status };
}

export class UnitsService {
  public async getAll(includeAll: boolean, abortController: AbortController): Promise<HttpFetchResponse<Unit[]>> {
    if (!includeAll) {
      return await getAllByPermission();
    }

    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/unit/0/${MAX_SAFE_INTEGER_API}`;

    const response = await fetch(url, { signal: abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody.items, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getByIdTakeTeams(
    id: string | number,
    includeAll = true,
    abortController: AbortController
  ): Promise<HttpFetchResponse<Team[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/unit/${id}${includeAll ? '' : '/me'}`;

    const response = await fetch(url, { signal: abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody.teams, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getById(id: string | number, abortController: AbortController): Promise<HttpFetchResponse<Unit>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/unit/${id}`;

    const response = await fetch(url, { signal: abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getAllByPermission(): Promise<HttpFetchResponse<Unit[]>> {
    return await this.getAllByPermission();
  }
}

export const unitsService = new UnitsService();
