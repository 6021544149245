import { createStore, ReducerType, useStore } from 'react-hookstore';
import { GenericPayload } from '~/app/core';

const name = 'ROOT/EXAMPLE';

enum Type {
  SET_REFRESH = 'ROOT/REFRESH/SET_REFRESH'
}

type Payload = GenericPayload<Type>;

interface State {
  last: number;
}

const state: State = {
  last: Date.now()
};

const reducers: ReducerType<State, Payload> = function (state: State, { type, payload }) {
  switch (type) {
    case Type.SET_REFRESH:
      return { ...state, last: Date.now() };
    default:
      return { ...state };
  }
};

const store = createStore<State>(name, state, reducers);

export const RefreshStoreType = Type;
export const useRefreshStore = () => useStore<State>(store);
