import { BillableType } from '../forecast';
import { Currency } from '../number';
import { PaginationParameters } from '../pagination';

export interface Project {
  id: number;
  name: string;
  quoteId: string;
  clientName: string;
  alias: string;
  type: ProjectType;
  status: ProjectStatus;
  color: string;
  productScopesInDays: number | null;
  productLastActivity: Date | null;
  productFirstActivity: Date | null;
  owner: string;
  ownerId: number;
  linkCRM: string;

  rat: ProjectRAT | null;

  products: ProjectProduct[];

  totalPrice: number;
  productsDiscounts: number;
  globalDiscounts: number;
  totalWithDiscounts: number;
  totalRemaining: number;
  currency: Currency;

  hasForecastOrExternalEffort: boolean;
}

export interface ProjectRAT {
  id: number;
  createdOn: Date;
  employeeId: number;
  employeeName: string;
}

export interface ProjectProduct {
  id: number;
  name: string;
  effort: number | null;
  scope: number;
  priceDay: number;
  price: number;
  discount: number;
  total: number;
  remaining: number;
}

export interface ProjectEmployeesEmployeeDayByDay {
  day: number;
  employeesWorkedAtDay: EmployeesWorkedAtDay[];
}

export interface EmployeesWorkedAtDay {
  id: number;
  name: string;
  unitName: string | null;
  teamId: number | null;
  teamAlias: string | null;
  businessLineName: string | null;
  teamNumber: number | null;
  unitColor: string | null;
  billableType: BillableType;
}

export interface ProjectEmployeesEmployee {
  id: number;
  name: string;
  unitName: string | null;
  teamId: number | null;
  teamAlias: string | null;
  businessLineName: string | null;
  effort: number | null;
  externalEffort: number | null;
  effortNonBillable: number | null;
  teamNumber: number | null;
  unitColor: string | null;
  isExternal: boolean;
  idExternalEfforts: number[] | null;
}

export interface ProjectsByFilter {
  filterTab?: ProjectFilterTab;
  filter: string;
  types: ProjectType[];
  paginationParameters: PaginationParameters;
  headerFilters: HeaderFilter[];
}

export type HeaderFilter = {
  id: string;
  value: number[];
};

export type PaginatedProject<T> = {
  items: T[];
  total: number;
  totalWon: number;
  totalProbable: number;
  totalFinished: number;
  totalClientBlock: number;
  totalRAT: number;
  totalInconsistent: number;
};

export interface ProjectCreateEdit {
  id?: number;
  name?: string;
  alias?: string;
  type: ProjectType;
  ownerId: number;
  color: string;
  status: ProjectStatus;
  productScopesInDays?: number;
}

export interface ProjectFromProposal {
  id: number;
  quoteId: string;
  account: string;
  projectName: string;
  status: ProjectStatus;
  owner: string;
  products: ProductProjectFromProposal[];
  totalPrice: number;
  productsDiscounts: number;
  globalDiscounts: number;
  totalWithDiscounts: number;
  currency: Currency;
}

export interface ProductProjectFromProposal {
  id: number;
  name: string;
  effort: number | null;
  haveEffortNonBillable: boolean;
  scope: number;
  scopeOld: number | null;
  priceDay: number;
  price: number;
  isDeleted: boolean;
  isMatched: boolean;
  nameMatched: string;
  linkCRM: string;
  discount: number;
  total: number;
}

export type ProjectEffortSummary = {
  totalBillableEffort: number;
  nonBillableEffort: number;
  nonBillableBySalesEffort: number;
  allUnitsEffort: number;
  externalEffort: number;
  regularEffort: number;
  breakdownEffort: Array<{
    unit: {
      id: number;
      name: string;
      teams: unknown[];
    };
    effort: number;
  }>;
  lastActivity: Date;
};

export type ProjectCreateRAT = {
  projectId: number;
  employeeId: number;

  question1: number;
  question2: number[];
  question3: number[];
  question4: number;
  question5: number;
};

export enum ProjectType {
  Regular = '0',
  Internal = '1',
  Plain = '2'
}

export enum ProjectStatus {
  Probable = '1',
  Won = '2',
  Finished = '3',
  ClientBlock = '4',
  NoStartDate = '5',
  PendingAdjustment = '6',
  Unforecastable = '7'
}

export enum ProjectFilterTab {
  All = 0,
  Inconsistent = 1
}

export const DAYS_PER_SPRINT = 20;
