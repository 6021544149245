import { formatDistance } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RotateClockwise } from 'tabler-icons-react';
import { DynamicStyleClass, Tooltip, getUtcDate } from '~/app/shared';

const MAX_TIME_TO_REFRESH = 30000;

type ForecastRefreshProps = {
  onRefresh: () => Promise<void>;
  forceRefreshDate?: boolean;
};

export function ForecastRefresh(props: ForecastRefreshProps) {
  const { onRefresh, forceRefreshDate } = props;

  const [lastRefresh, setLastRefresh] = useState(getUtcDate());
  const [timestamp, setTimestamp] = useState(performance.now());
  const [t] = useTranslation();

  const refreshText = useMemo(
    () => timestamp && formatDistance(lastRefresh, getUtcDate(), { addSuffix: true }),
    [lastRefresh, timestamp]
  );

  const basicClasses: DynamicStyleClass = new DynamicStyleClass().fromRecord({
    forecast__icon: true,
    'forecast__icon--loading': forceRefreshDate
  });

  useEffect(() => {
    let animationFrameId;

    function timestampLoop() {
      const now = performance.now();

      if (now - timestamp > MAX_TIME_TO_REFRESH) {
        setTimestamp(now);
      }

      animationFrameId = requestAnimationFrame(timestampLoop);
    }

    timestampLoop();
    return () => cancelAnimationFrame(animationFrameId);
  }, [timestamp]);

  useEffect(() => {
    function forceRefreshWhenIsNeeded() {
      if (!forceRefreshDate) {
        return;
      }

      setLastRefresh(getUtcDate());
    }

    forceRefreshWhenIsNeeded();
  }, [forceRefreshDate]);

  const handleRefreshButtonClick = async () => {
    setLastRefresh(getUtcDate());
    await onRefresh();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '0.5rem', alignItems: 'center' }}>
      <Tooltip title={t('common.loading')}>
        <div className="forecast__action" onClick={handleRefreshButtonClick}>
          <RotateClockwise className={basicClasses.toString()} />
        </div>
      </Tooltip>

      <div className="forecast__refreshed">{refreshText}</div>
    </div>
  );
}
