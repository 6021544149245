import { useMemo } from 'react';
import {
  AlertCircle,
  AlignBoxLeftMiddle,
  Basket,
  Beach,
  BuildingCommunity,
  CalendarEvent,
  CircleX,
  Lock,
  LockSquare,
  Notebook,
  SquareAsterisk,
  SquareDot,
  SquareForbid2,
  SquareOff
} from 'tabler-icons-react';
import { BillableType, ProductStatus } from '~/app/shared';
import { BulletStyle } from '~/app/shared/product/product.helper';
import { ReactComponent as SquareOffS } from '~/assets/icons/SquareOffS.svg';
import { ReactComponent as PlainLogo } from '~/assets/images/LogoPlainForecast.svg';
import './forecast-info.css';

export function RenderBodyCellDragInfo(props) {
  const {
    styles,
    productBulletStyles,
    title,
    subtitle,
    totalCells = 1,
    isSpecial,
    isBilledOtherTime,
    child,
    cell,
    productStatus
  } = props;

  const isLong = useMemo(() => totalCells > 1, [totalCells]);

  const customStyles = useMemo(() => {
    const width = styles['--width'];
    const height = styles['--height'];

    return { ...styles, width, height };
  }, [styles]);

  const customProductBulletStylesByProbableStatus = useMemo(() => {
    if (productStatus !== parseInt(ProductStatus.Probable)) {
      return { ...productBulletStyles };
    }
    const backgroundColor = productBulletStyles['backgroundColor'];
    const backgroundImage = `repeating-linear-gradient(
      -45deg,
      #FFFFFF 0px,
      #FFFFFF 4px,
      ${backgroundColor} 4px,
      ${backgroundColor} 8px
    )`;

    return { backgroundSize: null, backgroundImage: backgroundImage };
  }, [productBulletStyles, productStatus]);

  const eventIcon = [
    {
      icon: <AlertCircle />,
      box: 'Idle'
    },
    {
      icon: <CircleX />,
      box: 'Not available'
    },
    {
      icon: <Notebook />,
      box: 'Other'
    },
    {
      icon: <Basket />,
      box: 'Sales support'
    },
    {
      icon: <BuildingCommunity />,
      box: 'Sick or leave'
    },
    {
      icon: <Beach />,
      box: 'Vacation'
    },
    {
      icon: <CalendarEvent />,
      box: 'Bank holiday'
    }
  ];

  const productIcons = [
    {
      icon: <AlignBoxLeftMiddle />,
      box: BulletStyle.Normal
    },
    {
      icon: <SquareAsterisk />,
      box: BulletStyle.Internal
    },
    {
      icon: <SquareOff />,
      box: BulletStyle.NonBillable
    },
    {
      icon: <PlainLogo />,
      box: BulletStyle.Plain
    },
    {
      icon: <SquareOffS />,
      box: BulletStyle.NonBillableSales
    }
  ];

  interface IconProps {
    icon: React.ReactElement;
    box: BulletStyle | string;
  }

  interface EventIcon {
    title?: string;
    type?: string | BulletStyle;
    index?: IconProps[];
  }

  function getIcon({ type, index }: EventIcon): React.ReactElement {
    return index.filter((e) => e.box === type).map((item) => item.icon)[0];
  }

  return title ? (
    <div className={`forecast-info`} style={customStyles}>
      <span
        className={`forecast-info__bullet ${isSpecial ? 'forecast-info__bullet--special' : ''}`}
        style={customProductBulletStylesByProbableStatus}
      ></span>
      <h6 className="forecast-info__title">
        <span className="forecast-info__icon">
          {cell.haveBilling ? (
            <Lock className="forecast-circle-tooltip__icon" />
          ) : productStatus === parseInt(ProductStatus.Finished) ? (
            <LockSquare />
          ) : productStatus === parseInt(ProductStatus.ClientBlock) ? (
            <SquareForbid2 />
          ) : cell.billableType === BillableType.BilledOtherTime ? (
            <SquareDot />
          ) : isSpecial ? (
            getIcon({ type: title, index: eventIcon })
          ) : cell.billableType === BillableType.NonBillableBySales ? (
            <SquareOffS style={{ stroke: 'none' }} />
          ) : (
            getIcon({ type: productBulletStyles.productType, index: productIcons })
          )}
        </span>
        {isLong ? title : ''}
      </h6>
      {child}
    </div>
  ) : (
    <></>
  );
}
