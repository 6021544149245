import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Forecast = lazy(() => import('./Forecast'));
const ForecastNoTeam = lazy(() => import('./ForecastNoTeam'));

export default (
  <>
    <Route path="/forecast" element={<ForecastNoTeam />} />
    <Route path="/forecast/:unit/:team" element={<Forecast />} />
    <Route path="/forecast/:unit/:team/:year" element={<Forecast />} />
  </>
);
