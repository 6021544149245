import { useTranslation } from 'react-i18next';

import { Tooltip } from '~/app/shared';

type SelectSpecialEventProps = {
  onSelectSpecialEvent: (id: number) => void;
  specialEffortProducts: any[];
  selectedSpecialEvent?: number;
};

export function ForecastSpecialEvent(props: SelectSpecialEventProps) {
  const { specialEffortProducts, onSelectSpecialEvent, selectedSpecialEvent } = props;
  const [t] = useTranslation();

  return (
    <div className="forecast-selector__events">
      {specialEffortProducts.map((effort) => (
        <Tooltip title={t(effort.item.description)} key={effort.name}>
          <button
            type="button"
            style={{ backgroundColor: effort.item.theme }}
            className={`forecast-selector__icons ${
              selectedSpecialEvent === effort.id ? ' forecast-selector__selected' : ''
            }`}
            onClick={() => {
              onSelectSpecialEvent(effort.id);
            }}
          >
            <effort.item.icon />
          </button>
        </Tooltip>
      ))}
    </div>
  );
}
