import { eachDayOfInterval, isWeekend } from 'date-fns';
import debounce from 'debounce-async';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { productsService } from '~/app/products';
import {
  BillableType,
  FAST_FETCH_DEBOUNCE,
  FormAsyncSelect,
  FormSelect,
  HttpStatus,
  SPECIAL_KEY,
  buildSpecialEffortProductsWithIcons,
  getSavedFilter,
  getSavedTeamId,
  isAbortError,
  parseDateWithTimezoneToString,
  productsToSelectOptions
} from '~/app/shared';
import { teamsService } from '~/app/teams/shared';
import { ForecastForEmployeeList } from '../forecast-cell.model';
import { OptionBullet, SingleValue, isSpecialEvent } from '../forecast-product-cell-selector';
import { ForecastSpecialEvent } from '../forecast-product-cell-selector/ForecastSpecialEvent';
import { forecastService } from '../forecast.service';
import { GetForecastType } from '../forecast.store';

export function ForecastBulkAddForm({ onSubmitPanel, onHidePanel }) {
  const [t] = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { isDirty, errors, isSubmitted }
  } = useForm();
  const [defaultProducts, setDefaultProducts] = useState<any>();
  const storedTeam = Number(getSavedTeamId());
  const storedYear = getSavedFilter(GetForecastType.GET_YEAR);
  const [defaultEmployees, setDefaultEmployees] = useState<any>();
  const [buildedSpecialCategories] = useState(buildSpecialEffortProductsWithIcons(t));
  const [selectedSpecialEvent, setSelectedSpecialEvent] = useState<number>();
  const [selectedKey, setSelectedKey] = useState<FormKeys>();
  const [selectedBillable, setSelectedBillable] = useState<BillableType>();
  const [minToDate, setMinToDate] = useState(new Date().toString());

  const filteredSpecialCategories = buildedSpecialCategories.filter((category) => category.id === 5);

  const onFromDateChange = useCallback((date) => {
    if (!date) {
      return;
    }

    const dateToParse = new Date(date);
    const currentDate = new Date(dateToParse.getFullYear() + 1, 11, 31);

    return parseDateWithTimezoneToString(currentDate);
  }, []);

  enum FormKeys {
    employees = 'employees',
    fromDate = 'fromDate',
    toDate = 'toDate',
    product = 'product',
    isBillable = 'billable',
    nonBillable = 'nonbillable',
    isBilledOtherTime = 'other',
    nonBillableBySales = 'nonBillableBySales'
  }

  const watchedProduct = watch(FormKeys.product);
  const watchedFromDate = watch(FormKeys.fromDate);

  const isSpecialEventValidation = isSpecialEvent(selectedSpecialEvent, watchedProduct);

  const handleSpecialEvent = (id: number) => {
    setSelectedSpecialEvent(id);

    const specialEvent = buildedSpecialCategories.find((x) => x.id === id);

    if (!specialEvent) {
      return;
    }

    if (specialEvent.clientName === SPECIAL_KEY) {
      setSelectedBillable(null);
      reset({ ...getValues(), product: null });
    }
  };
  const updateFromDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMinToDate(e.target.value);
  };

  const getData = useCallback(
    async (inputValue: string) => {
      if (inputValue.length < 1 && defaultProducts) {
        return defaultProducts;
      }

      const { data: fetchedProducts } = await productsService.getForSelector({
        year: storedYear,
        teamId: inputValue ? undefined : storedTeam,
        search: inputValue,
        paginationParameters: {
          pageNumber: 0,
          pageSize: 10,
          propertySort: 'name',
          isAscending: true
        }
      });

      const products = productsToSelectOptions(fetchedProducts.items);

      if (inputValue.length < 1 && !defaultProducts) {
        setDefaultProducts(products);
      }

      return products;
    },
    [defaultProducts, storedTeam, storedYear]
  );

  const productsToSelectPromise = useMemo(() => debounce(getData, FAST_FETCH_DEBOUNCE), [getData]);

  const getRageDates = useCallback((from: string, to: string) => {
    const range: Interval = { start: new Date(from), end: new Date(to) };
    const amountOfRangeDates = eachDayOfInterval(range);

    return amountOfRangeDates.filter((date) => !isWeekend(date)).map((d) => parseDateWithTimezoneToString(d));
  }, []);

  const getEmployeeList = useCallback(async (teamId) => {
    const { data } = await teamsService.getById(teamId, new AbortController());
    if (!data?.employees) {
      return;
    }

    const employees = data.employees || [];
    const selectOptions = employees.map((employee) => ({
      value: employee.id,
      label: employee.name
    }));

    return selectOptions;
  }, []);

  type FormLabelProps = {
    content: any;
    controlId: string;
    title?: string;
  };

  const FormLabel = ({ content, controlId, title }: FormLabelProps) => (
    <Form.Group controlId={controlId}>
      <Form.Label className="forecast-bulk-add__control ">
        {title && <h4 className="forecast-bulk-add__label form-label-required">{t(title)}</h4>}
        {content}
      </Form.Label>
    </Form.Group>
  );

  const FormCheck = ({ value, label, disabled = false }) => {
    return (
      <Form.Group controlId={value}>
        <Form.Label onClick={() => onChangeBillable(value)}>
          <Form.Check
            label={t(label)}
            name={value}
            type="radio"
            className="forecast-bulk-add__switch forecast-bulk-add__switch--label"
            defaultChecked={selectedKey === value}
            isInvalid={!isSpecialEventValidation && errors.radiogroup}
            disabled={isSpecialEventValidation || disabled}
          />
        </Form.Label>
      </Form.Group>
    );
  };

  const onChangeBillable = (newSelection: BillableType) => {
    if (isSpecialEventValidation) {
      return;
    }
    setSelectedBillable(newSelection);
  };

  const onSubmit = useCallback(
    async (data) => {
      const { employees, product, fromDate, toDate } = data;

      if (!employees) {
        return;
      }
      try {
        const body: ForecastForEmployeeList = {
          dates: getRageDates(fromDate, toDate),
          productId: product?.value?.id || null,
          billableType: selectedBillable ?? BillableType.None,
          employeeIds: employees.map((s) => s.value),
          specialEffort: selectedSpecialEvent || null
        };
        const response = await forecastService.addForecastForMultipleEmployees(body, storedTeam);

        if (response.status === HttpStatus.StatusAccepted) {
          onSubmitPanel();
        }
      } catch (error) {
        if (!isAbortError(error)) {
          console.error(error);
        }
      }
    },
    [getRageDates, onSubmitPanel, selectedBillable, selectedSpecialEvent, storedTeam]
  );

  const isFormInvalid = () => {
    const employees = getValues(FormKeys.employees);

    const isNewList = employees === undefined;
    const list = employees || [];
    const isListEmpty = list?.length <= 0;
    const newListCheck = isNewList ? (isSubmitted ? isListEmpty : false) : isListEmpty;

    return isDirty ? newListCheck : false;
  };

  useEffect(() => {
    const selectedProduct = watchedProduct?.value;

    if (!selectedProduct) {
      return;
    }

    setSelectedSpecialEvent(null);
    if (isSpecialEventValidation) {
      setSelectedKey(null);
    }
  }, [watchedProduct?.value, isSpecialEventValidation]);

  useEffect(() => {
    const initEmployeeList = async () => {
      if (defaultEmployees || !storedTeam) {
        return;
      }
      const options = await getEmployeeList(storedTeam);
      setDefaultEmployees(options);
    };

    initEmployeeList();
  }, [defaultEmployees, getEmployeeList, storedTeam]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <p className="forecast-bulk__label form-label-note">{t('forecast.bulk-add.warning')}</p>
      <br></br>
      <FormLabel
        controlId={FormKeys.employees}
        title={'forecast.bulk-add.members'}
        content={
          <FormSelect
            name={FormKeys.employees}
            placeholder={t('forecast.create.product-placeholder')}
            options={defaultEmployees}
            control={control}
            isMultiple
            isInvalid={isFormInvalid()}
          />
        }
      />
      <FormLabel
        controlId={FormKeys.fromDate}
        title={'forecast.bulk-add.from'}
        content={
          <Form.Control
            type="date"
            value={minToDate}
            {...register(FormKeys.fromDate, {
              required: { value: true, message: t('form.errors.required') },
              onChange: (e) => updateFromDate(e)
            })}
            isInvalid={errors.fromDate}
          />
        }
      />
      <FormLabel
        controlId={FormKeys.toDate}
        title={'forecast.bulk-add.to'}
        content={
          <>
            <Form.Control
              type="date"
              min={minToDate}
              {...register(FormKeys.toDate, {
                required: { value: true, message: t('form.errors.required') },
                min: {
                  value: watchedFromDate,
                  message: t('form.errors.minDate', [getValues(FormKeys.fromDate)])
                },
                max: {
                  value: onFromDateChange(getValues(FormKeys.fromDate)),
                  message: t('form.errors.maxDateReach', [
                    getValues(FormKeys.fromDate),
                    onFromDateChange(getValues(FormKeys.fromDate))
                  ])
                }
              })}
              isInvalid={errors.toDate}
            />
            <Form.Control.Feedback type="invalid">{errors.toDate?.message}</Form.Control.Feedback>
          </>
        }
      />

      <FormLabel
        controlId={FormKeys.product}
        title={'forecast.bulk-add.product'}
        content={
          <>
            <FormAsyncSelect
              name={FormKeys.product}
              className="custom-form-select--default-height mb-3"
              placeholder={t('forecast.create.product-placeholder')}
              promiseOptions={productsToSelectPromise}
              control={control}
              components={{ Option: OptionBullet, SingleValue }}
              rules={{ required: { value: !selectedSpecialEvent, message: t('form.errors.required') } }}
            />
            <ForecastSpecialEvent
              specialEffortProducts={filteredSpecialCategories}
              onSelectSpecialEvent={handleSpecialEvent}
              selectedSpecialEvent={selectedSpecialEvent}
            />
          </>
        }
      />

      <h4 className="forecast-bulk-add__label form-label-required">{t('forecast.actions.billable')}</h4>
      <div className="forecast-bulk-add__radio">
        <div className="forecast-bulk-add__types">
          <FormCheck value={BillableType.Billable} label={'forecast.create.billable'} />
          <FormCheck value={BillableType.NonBillable} label={'forecast.create.non-billable'} />
        </div>
        <div className="forecast-bulk-add__types">
          <FormCheck value={BillableType.BilledOtherTime} label={'forecast.create.billed-other-time'} />
          <FormCheck
            value={BillableType.NonBillableBySales}
            label={'forecast.create.non-billable-by-sales'}
            disabled={true}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <Button variant="secondary" type="reset" className="mx-2 btn-light" onClick={onHidePanel}>
          {t('common.cancel')}
        </Button>

        <Button
          variant="primary"
          type="submit"
          className="mx-2 "
          disabled={!isDirty && !watchedProduct?.value && !selectedSpecialEvent}
        >
          {t('common.add')}
        </Button>
      </div>
    </Form>
  );
}
