import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';

import {
  HttpStatus,
  ModalConfirmation,
  PageName,
  Paginated,
  Table,
  TableAction,
  TableCellBadge,
  Team,
  getTeamName,
  isAbortError,
  toastService,
  transformIntegerToStringFormatSync,
  useChangePageTitle
} from '~/app/shared';
import Filter from '~/app/shared/filter/Filter';
import { RefreshStoreType, useRefreshStore } from '~/store';

import { ReactComponent as UserIcon } from '~/assets/icons/User.svg';
import { ReactComponent as WarningIcon } from '~/assets/icons/Warning.svg';
import { teamsService } from '.';
import * as data from '../../../app/shared/table/table.helper.json';
import { TeamsStoreType, useTeamsStore } from '../teams.store';

const rawColumns = [
  {
    Header: 'teams.list.columns.id',
    accessor: 'id',
    hidden: true
  },
  {
    Header: 'teams.list.columns.number',
    accessor: 'number',
    Cell: ({ value }) => <TableCellBadge text={getTeamName(value)} />,
    defaultSort: true,
    defaultSortAsc: true
  },
  {
    Header: 'teams.list.columns.unit',
    accessor: 'unit',
    Cell: ({ value }) => <TableCellBadge text={value.name} />
  },
  {
    Header: 'teams.list.columns.manager',
    accessor: 'manager.name'
  },
  {
    Header: 'teams.list.columns.employeesCount',
    accessor: 'currentEmployeesCount'
  }
];

export const TeamsList: React.FunctionComponent = () => {
  const [t] = useTranslation();

  const [id, setId] = useState<number | null>(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState<boolean>(false);

  const teamWithEmployeesErrorDialogRef = useRef<HTMLDialogElement>(null);
  const [teamWithEmployeesErrorState, setTeamWithEmployeesErrorState] = useState<
    | {
        message: string;
        employees: Array<{ id: number; name: string }>;
      }
    | undefined
  >();

  const columns = useMemo(() => rawColumns.map((column) => ({ ...column, Header: t(column.Header) })), [t]);

  const [teamsState, teamsDispatch] = useTeamsStore();
  const [refreshState, refreshDispatch] = useRefreshStore();

  function openTeamWithEmployeesErrorDialog(): void {
    teamWithEmployeesErrorDialogRef.current.showModal();
  }

  const teamTableRowActions = useMemo(
    () => ({
      [TableAction.Edit]: (team: Team) => teamsDispatch({ type: TeamsStoreType.SET_SELECTED, payload: team.id }),
      [TableAction.Delete]: (team: Team) => showDeleteModal(team.id)
    }),
    [teamsDispatch]
  );

  function showDeleteModal(id: number): void {
    setId(id);
    setDisplayConfirmationModal(true);
  }

  function hideConfirmationModal(): void {
    setDisplayConfirmationModal(false);
  }

  useEffect(
    function onUnmount() {
      return () => {
        teamsDispatch({ type: TeamsStoreType.SET_FILTER, payload: null });
        refreshDispatch({ type: RefreshStoreType.SET_REFRESH });
      };
    },
    [refreshDispatch, teamsDispatch]
  );
  useChangePageTitle(PageName.TEAMS);

  const deleteTeam = useCallback(async (): Promise<void> => {
    try {
      const response = await teamsService.delete(id);

      if (response.status === HttpStatus.StatusOK) {
        toastService.success(t('teams.delete.success'));

        refreshDispatch({ type: RefreshStoreType.SET_REFRESH });
        return;
      }

      if (response.status === HttpStatus.StatusBadRequest) {
        if (response.data.hasOwnProperty('employees')) {
          setDisplayConfirmationModal(false);

          setTeamWithEmployeesErrorState({
            message: response.data.detail,
            employees: response.data['employees']
          });

          openTeamWithEmployeesErrorDialog();
          return;
        }

        toastService.errorMessage(response.data.detail);
        return;
      }

      if (response.status === HttpStatus.StatusNotFound) {
        toastService.errorMessage(response.data.detail);
        return;
      }
    } catch (error) {
      if (!isAbortError(error)) {
        toastService.error(error);
      }
    } finally {
      setDisplayConfirmationModal(false);
    }
  }, [id, refreshDispatch, t]);

  const fetchData = useCallback(
    async (
      abortController,
      pageIndex,
      pageSize,
      sortId = data['teams'].defaultSortingColumn,
      isAsc = data['teams'].defaultAscOrder
    ): Promise<Paginated<Team>> => {
      const result = await teamsService.get({
        filter: teamsState?.filter ?? '',
        paginationParameters: {
          pageNumber: pageIndex,
          pageSize: pageSize,
          propertySort: sortId,
          isAscending: isAsc
        },
        abortController
      });

      return result.data;
    },
    [teamsState?.filter]
  );

  const handleOnChangeFilterEvent = useCallback(
    (filter): void => {
      teamsDispatch({ type: TeamsStoreType.SET_FILTER, payload: filter });
      refreshDispatch({ type: RefreshStoreType.SET_REFRESH });
    },
    [refreshDispatch, teamsDispatch]
  );

  return (
    <>
      <Card>
        <Card.Header>{t('teams.list.subtitle')}</Card.Header>

        <Card.Body>
          <Filter
            title={t('teams.filter.title')}
            placeholder={t('common.teams-filter-placeholder')}
            defaultValue={teamsState?.filter}
            onChange={handleOnChangeFilterEvent}
          />

          <Table
            columns={columns}
            fetchData={fetchData}
            pageSize={10}
            actions={teamTableRowActions}
            refresh={refreshState.last}
          />

          <ModalConfirmation
            showModal={displayConfirmationModal}
            confirmModal={deleteTeam}
            hideModal={hideConfirmationModal}
          />
        </Card.Body>
      </Card>

      <dialog ref={teamWithEmployeesErrorDialogRef} style={{ width: '439px' }}>
        {teamWithEmployeesErrorState ? (
          <form method="dialog" className="team-with-employees-error-dialog">
            <header className="team-with-employees-error-dialog__header">
              <span className="team-with-employees-error-dialog__header--icon">
                <WarningIcon />
              </span>

              <h2 className="team-with-employees-error-dialog__header--title">
                {t('teams.list.team-with-employees-error.title', [transformIntegerToStringFormatSync(id)])}
              </h2>
            </header>

            <output className="team-with-employees-error-dialog__message">{teamWithEmployeesErrorState.message}</output>

            <div className="team-with-employees-error-dialog__content">
              <p className="team-with-employees-error-dialog__content--description">
                {t('teams.list.team-with-employees-error.list-descriptor')}
              </p>

              <ul className="team-with-employees-error-dialog__content--list">
                {teamWithEmployeesErrorState.employees.map((employee) => (
                  <li key={employee.id}>
                    <UserIcon />

                    <span>{employee.name}</span>
                  </li>
                ))}
              </ul>
            </div>

            <menu className="team-with-employees-error-dialog__menu">
              <button type="submit" data-preflight="true">
                {t('teams.list.team-with-employees-error.close')}
              </button>
            </menu>
          </form>
        ) : null}
      </dialog>
    </>
  );
};
