import { Tooltip } from '~/app/shared';
import { useForecastStore } from './forecast.store';

export function RenderLeftColumnCell({ style, rowIndex }) {
  const [forecastState] = useForecastStore();

  const employee = forecastState?.rows[rowIndex]?.employee;
  const employeeHiringDate = new Date(employee?.hiringDate);

  if (!employee) {
    return null;
  }

  if (forecastState.visibleEndDay < employeeHiringDate) {
    return null;
  }

  return (
    <Tooltip title={employee.name} placement="right">
      <div className="forecast__employee" style={style}>
        {employee.name}
      </div>
    </Tooltip>
  );
}
