import { COLOR_ALPHA_TAGS, hexToColor } from '../color';

export enum EmployeeArea {
  Other = '0',
  Data = '1',
  AI = '2',
  Dev = '3',
  Cloud = '4',
  Mobile = '5',
  Research = '6',
  Design = '7',
  Testing = '8',
  Delivery = '9',
  M365 = '11'
}

export const EmployeeAreaColor = (alpha = COLOR_ALPHA_TAGS) => ({
  Other: hexToColor('#ffa113', alpha),
  Data: hexToColor('#ff4387', alpha),
  AI: hexToColor('#ff7b43', alpha),
  Dev: hexToColor('#2697ff', alpha),
  Cloud: hexToColor('#26e5ff', alpha),
  Mobile: hexToColor('0d4487', alpha),
  Research: hexToColor('#19e9aa', alpha),
  Design: hexToColor('#ff43ca', alpha),
  Testing: hexToColor('#377aff', alpha),
  Delivery: hexToColor('#ffcf26', alpha),
  M365: hexToColor('#7f6ced', alpha)
});
export const EmployeeAreaColorForChart = () => ({
  Other: hexToColor('#ffa113'),
  Data: hexToColor('#ff4387'),
  AI: hexToColor('#ff7b43'),
  Dev: hexToColor('#2697ff'),
  Cloud: hexToColor('#26e5ff'),
  Mobile: hexToColor('0d4487'),
  Research: hexToColor('#19e9aa'),
  Design: hexToColor('#ff43ca'),
  Testing: hexToColor('#377aff'),
  Delivery: hexToColor('#ffcf26'),
  M365: hexToColor('#7f6ced')
});
