import { createStore, ReducerType, useStore } from 'react-hookstore';
import { GenericPayload } from '~/app/core';
import { Product, saveFilter } from '~/app/shared';
import { ForecastRow } from '.';

const name = 'ROOT/FORECAST';

enum Type {
  RESET = 'ROOT/FORECAST/RESET',
  SET_PRODUCTS = 'ROOT/FORECAST/SET_PRODUCTS',
  SET_ROWS = 'ROOT/FORECAST/SET_ROWS',
  SET_DAYS = 'ROOT/FORECAST/SET_DAYS',
  SET_DRAGGING = 'ROOT/FORECAST/SET_DRAGGING',
  SET_VISIBLE_DATES = 'ROOT/FORECAST/SET_VISIBLE_DATES',
  SET_YEAR = 'ROOT/FORECAST/SET_FORECAST_YEAR'
}

enum ForecastType {
  GET_YEAR = 'forecastYear'
}

type Payload = GenericPayload<Type>;

interface State {
  rows: ForecastRow[];
  allDays: Date[];
  year: number;
  products: Product[];
  isDragging: boolean;
  visibleStartDay: Date;
  visibleEndDay: Date;
}

const state: State = {
  rows: [],
  allDays: [],
  year: 0,
  products: [],
  isDragging: false,
  visibleStartDay: null,
  visibleEndDay: null
};

const reducers: ReducerType<State, Payload> = function (state: State, { type, payload }) {
  switch (type) {
    case Type.RESET:
      return {
        rows: [],
        allDays: [],
        year: 0,
        products: [],
        isDragging: false,
        visibleStartDay: null,
        visibleEndDay: null
      };
    case Type.SET_DAYS:
      return {
        ...state,
        allDays: payload,
        rows: [],
        products: [],
        isDragging: false,
        visibleStartDay: null,
        visibleEndDay: null
      };
    case Type.SET_ROWS:
      const rows = payload as ForecastRow[];

      return {
        ...state,
        rows,
        products: []
      };
    case Type.SET_PRODUCTS:
      const products = payload as Product[];
      return {
        ...state,
        products
      };

    case Type.SET_DRAGGING:
      return {
        ...state,
        isDragging: payload
      };

    case Type.SET_VISIBLE_DATES:
      return {
        ...state,
        visibleStartDay: new Date(payload.start?.toDateString()),
        visibleEndDay: new Date(payload.end?.toDateString())
      };

    case Type.SET_YEAR:
      if (payload) {
        saveFilter(ForecastType.GET_YEAR, payload);
      }

      return {
        ...state,
        year: payload
      };
    default:
      return { ...state };
  }
};

const store = createStore<State>(name, state, reducers);

export const ForecastStoreType = Type;
export const GetForecastType = ForecastType;
export type ForecastStoreState = State;
export const useForecastStore = () => useStore<State>(store);
