import { addBusinessDays, compareAsc } from 'date-fns';
import {
  BillableType,
  ContentTypes,
  Employee,
  ForecastCapacity,
  HttpFetchResponse,
  HttpMethod,
  ProblemDetails,
  ProblemDetailsErrorsExtension,
  Product,
  formatDateForServer,
  getUtcDate
} from '~/app/shared';
import { ForecastCell, ForecastCellEmployee, ForecastCellSimplified, ForecastForEmployeeList, ForecastRow } from '.';

export interface ForecastServiceGetForecastOptions {
  fromDate: Date;
  daysToAdd: number;
  selectedTeamId: string | number;
  abortController: AbortController;
}

class ForecastService {
  public generateDates(startDate: Date, days: number): Date[] {
    const dates = [];

    for (let i = 0; i < days; i++) {
      dates.push(addBusinessDays(startDate, i));
    }

    return dates;
  }

  public async getForecast(options: ForecastServiceGetForecastOptions): Promise<HttpFetchResponse<ForecastCell[]>> {
    const from = formatDateForServer(options.fromDate);
    const to = formatDateForServer(addBusinessDays(options.fromDate, options.daysToAdd));

    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/forecast/${from}/${to}/${options.selectedTeamId}`;

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody?.items || [], status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getProductsReport(ids: Array<Product['id']>): Promise<HttpFetchResponse<ForecastCapacity[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/forecast/product';
    ids.forEach((id) => url.searchParams.set('ids', `${id}`));

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody?.items, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async updateForecast(
    cells: ForecastCellSimplified[],
    teamId: number
  ): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/forecast/${teamId}`;

    const response = await fetch(url, {
      method: HttpMethod.POST,
      body: JSON.stringify(cells),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async addForecastForMultipleEmployees(
    body: ForecastForEmployeeList,
    teamId: number
  ): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);

    url.pathname = `/forecast/bulkAdd/${teamId}`;

    const response = await fetch(url, {
      method: HttpMethod.POST,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public addEmployeesToForecast(fromDate: Date, forecast: ForecastCell[], employees: Employee[]): ForecastCell[] {
    const employeesNoForecast = employees?.filter(
      (e) =>
        forecast.find((f) => f.employee.id === e.id) === undefined &&
        (e.endDate === undefined || new Date(e.endDate) > fromDate)
    );

    if (employeesNoForecast?.length > 0) {
      const cells = this.getEmptyForecastForEmployees(employeesNoForecast, fromDate);
      const allCells = [...forecast, ...cells];
      return allCells.sort((a, b) => a.employee.name.localeCompare(b.employee.name));
    }

    return forecast.sort((a, b) => a.employee.name.localeCompare(b.employee.name));
  }

  public getCompleteTimeline(employee: ForecastCellEmployee, allDates: Date[], cells: ForecastCell[]): ForecastCell[] {
    return allDates.map((dateString) => {
      const date = dateString;

      const cell = cells.find((cell) => {
        cell.date = typeof cell?.date === 'string' ? getUtcDate(new Date(cell?.date)) : cell?.date;

        return compareAsc(cell.date, date) === 0;
      });

      if (!cell) {
        return { employee, date } as ForecastCell;
      }

      return cell;
    });
  }

  public convertForecastToRows(newCells: ForecastCell[], allDates: Date[]): ForecastRow[] {
    const newEmployees = newCells
      .map((x) => x.employee)
      .filter((value, index, array) => array.findIndex((t) => t.id === value.id) === index);

    const newRows = [];
    newEmployees.forEach((employee) => {
      const timeline = this.getCompleteTimeline(
        employee,
        allDates,
        newCells.filter((x) => x.employee.id === employee.id)
      );

      newRows.push({
        employee,
        timeline
      });
    });

    return newRows;
  }

  public async countForecastsBeforeDate(
    hiringDate: Date,
    employeeId: number | string
  ): Promise<HttpFetchResponse<number>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/forecast/beforeDate`;

    const body = {
      hiringDate: hiringDate,
      employeeId: employeeId
    };

    const response = await fetch(url, {
      method: HttpMethod.POST,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async countForecastsAfterDate(endDate: Date, employeeId: number | string): Promise<HttpFetchResponse<number>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/forecast/afterDate`;

    const body = {
      endDate: endDate,
      employeeId: employeeId
    };

    const response = await fetch(url, {
      method: HttpMethod.POST,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async countForecastsByEmployeeId(employeeId: number | string): Promise<number> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/forecast/byEmployeeId`;

    const body = {
      employeeId: employeeId
    };

    const response = await fetch(url, {
      method: HttpMethod.POST,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return responseBody;
    }

    return await response.json();
  }

  public async deleteBefore(
    employeeId: string | number,
    hiringDate: Date
  ): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/forecast/before`;

    const body = {
      hiringDate: hiringDate,
      employeeId: employeeId
    };

    const response = await fetch(url, {
      method: HttpMethod.DELETE,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async delete(
    employeeId: string | number,
    endDate: Date
  ): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/forecast`;

    const body = {
      endDate: endDate,
      employeeId: employeeId
    };

    const response = await fetch(url, {
      method: HttpMethod.DELETE,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async deleteExternalEmployeeForecasts(
    employeeId: string | number
  ): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/forecast/externalEmployee`;

    const body = {
      employeeId: employeeId
    };

    const response = await fetch(url, {
      method: HttpMethod.DELETE,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  private getEmptyForecastForEmployees(employeesNoForecast: Employee[], fromDate: Date): ForecastCell[] {
    return employeesNoForecast.map(
      (employee) =>
        ({
          date: fromDate,
          employee,
          product: null,
          billableType: BillableType.None,
          haveBilling: false
        } as ForecastCell)
    );
  }
}

export const forecastService = new ForecastService();
