import { CSSProperties } from 'react';
import { Product } from '../product';
import { Project } from '../projects';
import { ProposalStatus } from '../proposal/proposal-status.enum';
import { ProposalType } from '../proposal/proposal-type.enum';
import { Team } from '../team';
import { Unit } from '../unit';

export interface ProposalListItem {
  id?: number;
  clientName: string;
  projectName: string;
  startDate: Date;
  type: ProposalType;
  status: ProposalStatus;
  forecastCategory: string;
  endDate?: Date;
  proposalAreas: ProposalAreaItem[];
  referenceId: string;
  ownerId: number;
  accountManagerId: number;
  ownerName: number;
  accountManagerName: number;
  proposalUnits: ProposalUnitItem[];
  url: string;
  dueDate?: Date;
  tentativeKickOffDate?: Date;
  projectId: number;
}

export interface ProposalAreaItem {
  area: string;
}

export interface ProposalUnitItem {
  displayName: string;
  color: string;
  order: number;
}

export interface TeamsProductsListItem {
  teams: Team[];
  unit: Unit;
  product: Product;
  project: Project;
  scope: string;
  lastActivity: string;
  stylesRow?: CSSProperties | undefined;
}

export interface ProposalByClientNameListItem {
  referenceId: string;
  type: ProposalType;
  status: ProposalStatus;
  crmStatus: string;
  proposalAreas: ProposalAreaItem[];
  proposalUnits: ProposalUnitItem[];
  ownerId: number;
  ownerName: string;
  accountManagerId: number;
  accountManagerName: string;
  startDate: Date;
}

export enum ProposalFilterTab {
  Regular = 0,
  SalesSupport = 1,
  Inconsistent = 2,
  MyRegular = 3,
  MySalesSupport = 4
}
