import { addBusinessDays, format } from 'date-fns';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertCircle,
  AlignBoxLeftMiddle,
  Basket,
  Beach,
  BuildingCommunity,
  CalendarEvent,
  CircleX,
  Lock,
  LockSquare,
  Notebook,
  SquareAsterisk,
  SquareDot,
  SquareForbid2,
  SquareOff
} from 'tabler-icons-react';
import { BillableType, ProductStatus, Show, enumKeyFromValue, getProductCellBulletStyles } from '~/app/shared';
import { ProductType } from '~/app/shared/product/product-type.enum';
import { BulletStyle } from '~/app/shared/product/product.helper';
import { ReactComponent as SquareOffS } from '~/assets/icons/SquareOffS.svg';
import { ReactComponent as PlainLogo } from '~/assets/images/LogoPlainForecast.svg';
import { ForecastCell } from './forecast-cell.model';
import './forecast-circle-tooltip.css';

interface CellTooltipInformationType {
  cellData: ForecastCell;
  maxNextTheSame: number;
  borderColor: string;
  comment: string;
  title: string;
  subtitle: string;
  productStatus;
  isSpecial: boolean;
}
const eventIcon = [
  {
    icon: <AlertCircle />,
    box: 'Idle'
  },
  {
    icon: <CircleX />,
    box: 'Not available'
  },
  {
    icon: <Notebook />,
    box: 'Other'
  },
  {
    icon: <Basket />,
    box: 'Sales support'
  },
  {
    icon: <BuildingCommunity />,
    box: 'Sick or leave'
  },
  {
    icon: <Beach />,
    box: 'Vacation'
  },
  {
    icon: <CalendarEvent />,
    box: 'Bank holiday'
  }
];

interface EventIcon {
  title?: string;
  type?: string | BulletStyle;
  index?: IconProps[];
}

interface IconProps {
  icon: React.ReactElement;
  box: BulletStyle | string;
}

export function CellTooltipInformation(props: CellTooltipInformationType) {
  const { cellData, borderColor, comment, maxNextTheSame, isSpecial, productStatus, subtitle, title } = props;
  const [t] = useTranslation();

  const productColor = borderColor || '#dfe6ec';
  const toolTipStyles = () => {
    return {
      '--border': productColor
    } as CSSProperties;
  };

  const { product, color, ...productBulletStyles } = getProductCellBulletStyles(cellData);

  const formatDate = (date: Date) => {
    return format(date, 'dd-MM-yyyy');
  };

  const getDay = (date: Date) => {
    return format(new Date(date), 'E');
  };

  const getFinalDate = () => {
    const date = new Date(cellData.date);
    const moreColumnsToDraw = maxNextTheSame;
    return addBusinessDays(date, moreColumnsToDraw);
  };

  const hideCommentForOneDay = (cells, com) => {
    return cells <= 1 && !!com === false ? 'is-hidden' : '';
  };

  const totalCells = (maxNextTheSame || 0) + 1;
  const productIcons = [
    {
      icon: <AlignBoxLeftMiddle />,
      box: BulletStyle.Normal
    },
    {
      icon: <SquareAsterisk />,
      box: BulletStyle.Internal
    },
    {
      icon: <SquareOff />,
      box: BulletStyle.NonBillable
    },
    {
      icon: <PlainLogo />,
      box: BulletStyle.Plain
    },
    {
      icon: <SquareOffS />,
      box: BulletStyle.NonBillableSales
    }
  ];
  function getIcon({ type, index }: EventIcon): React.ReactElement {
    return index.filter((e) => e.box === type).map((item) => item.icon)[0];
  }

  return (
    <div
      className={`forecast-circle-tooltip forecast-circle-tooltip${isSpecial ? '--highlight-left' : '--highlight-top'}`}
      style={
        productStatus === parseInt(ProductStatus.Probable)
          ? {
              backgroundImage: `repeating-linear-gradient(
              -45deg,
              #FFFFFF 0px,
              #FFFFFF 4px,
              ${productColor} 4px,
              ${productColor} 8px
            )`,
              backgroundSize: 'auto 3px',
              backgroundRepeat: 'no-repeat'
            }
          : toolTipStyles()
      }
    >
      {productStatus === parseInt(ProductStatus.Finished) ? (
        <LockSquare />
      ) : productStatus === parseInt(ProductStatus.ClientBlock) ? (
        <SquareForbid2 />
      ) : cellData.billableType === BillableType.BilledOtherTime ? (
        <SquareDot />
      ) : isSpecial ? (
        getIcon({ type: title, index: eventIcon })
      ) : cellData.billableType === BillableType.NonBillableBySales ? (
        <SquareOffS style={{ stroke: 'none' }} />
      ) : (
        getIcon({ type: productBulletStyles.productType, index: productIcons })
      )}
      <div className="forecast-circle-tooltip__column">
        <div className="forecast-circle-tooltip__head">
          <span className="forecast-circle-tooltip__title">{title}</span>
          <Show when={!isSpecial}>
            <span className="forecast-circle-tooltip__subtitle">{subtitle}</span>
          </Show>
          <Show when={!!cellData.date}>
            <div className="forecast-circle-tooltip__date">
              <div className="forecast-circle-tooltip__col">
                <span className="forecast-circle-tooltip__text is-spaced ">{t('common.from')}:</span>
                <span className="forecast-circle-tooltip__text">{t('common.to')}:</span>
              </div>
              <div className="forecast-circle-tooltip__col">
                <span className="forecast-circle-tooltip__text-light is-spaced">{getDay(cellData.date)}</span>
                <span className="forecast-circle-tooltip__text-light">{getDay(getFinalDate())}</span>
              </div>
              <div className="forecast-circle-tooltip__col">
                <b className="is-spaced">{formatDate(cellData.date)}</b>
                <b>{formatDate(getFinalDate())}</b>
              </div>
            </div>
          </Show>
        </div>

        <div className="forecast-circle-tooltip__footer">
          <Show when={cellData?.product?.type === ProductType.Internal}>
            <div className="forecast-circle-tooltip__icon-row">
              {productStatus === parseInt(ProductStatus.Finished) ? (
                <LockSquare className="forecast-circle-tooltip__icon" />
              ) : productStatus === parseInt(ProductStatus.ClientBlock) ? (
                <SquareForbid2 />
              ) : (
                <SquareAsterisk className="forecast-circle-tooltip__icon" />
              )}

              <span>{t('forecast.tooltip.special.internal')} </span>
            </div>
          </Show>

          <Show when={cellData?.product?.type === ProductType.Plain}>
            <div className="forecast-circle-tooltip__icon-row">
              {productStatus === parseInt(ProductStatus.Finished) ? (
                <LockSquare className="forecast-circle-tooltip__icon" />
              ) : productStatus === parseInt(ProductStatus.ClientBlock) ? (
                <SquareForbid2 />
              ) : (
                <PlainLogo className="forecast-circle-tooltip__icon" />
              )}
              <span>{t('forecast.tooltip.special.plain')} </span>
            </div>
          </Show>
          <Show when={cellData.billableType === BillableType.NonBillable}>
            <div className="forecast-circle-tooltip__icon-row">
              {productStatus === parseInt(ProductStatus.Finished) ? (
                <LockSquare className="forecast-circle-tooltip__icon" />
              ) : productStatus === parseInt(ProductStatus.ClientBlock) ? (
                <SquareForbid2 />
              ) : (
                <SquareOff className="forecast-circle-tooltip__icon" />
              )}

              <span>{t('forecast.tooltip.special.non-billable')} </span>
            </div>
          </Show>
          <Show when={cellData.billableType === BillableType.NonBillableBySales}>
            <div className="forecast-circle-tooltip__icon-row">
              {productStatus === parseInt(ProductStatus.Finished) ? (
                <LockSquare className="forecast-circle-tooltip__icon" />
              ) : productStatus === parseInt(ProductStatus.ClientBlock) ? (
                <SquareForbid2 />
              ) : (
                <SquareOffS className="forecast-circle-tooltip__icon" />
              )}

              <span>{t('forecast.tooltip.special.non-billable-by-sales')} </span>
            </div>
          </Show>
          <Show
            when={
              !isSpecial &&
              cellData.billableType === BillableType.Billable &&
              cellData?.product?.type === ProductType.Regular
            }
          >
            <div className="forecast-circle-tooltip__icon-row">
              {productStatus === parseInt(ProductStatus.Finished) ? (
                <LockSquare className="forecast-circle-tooltip__icon" />
              ) : productStatus === parseInt(ProductStatus.ClientBlock) ? (
                <SquareForbid2 />
              ) : (
                <Notebook className="forecast-circle-tooltip__icon" />
              )}
              <span>{t('forecast.tooltip.regular')} </span>
            </div>
          </Show>
          {cellData.billableType === BillableType.BilledOtherTime && (
            <span className="forecast-circle-tooltip__column">{t('forecast.tooltip.special.billed-other-time')} </span>
          )}
          <Show when={!!productStatus}>
            <span className="forecast-circle-tooltip__status">
              {t(`forecast.tooltip.special.${enumKeyFromValue(ProductStatus, productStatus)?.toLocaleLowerCase()}`)}
            </span>
          </Show>
          <Show when={cellData.haveBilling}>
            <div className="forecast-circle-tooltip__icon-row">
              <Lock className="forecast-circle-tooltip__icon" />
              <span>{t('forecast.tooltip.blockForBilling')} </span>
            </div>
          </Show>

          <span className="forecast-circle-tooltip__time">
            {totalCells} {t(totalCells > 1 ? 'common.days' : 'common.day')}
          </span>
        </div>
      </div>
      <div
        className={`forecast-circle-tooltip__column forecast-circle-tooltip__column--small ${hideCommentForOneDay(
          totalCells,
          comment
        )}`}
      >
        <span>{comment || t('common.no-comments')}</span>
      </div>
    </div>
  );
}
