import {
  addBusinessDays,
  compareAsc,
  differenceInBusinessDays,
  eachDayOfInterval,
  endOfYear,
  isWeekend,
  startOfYear
} from 'date-fns';
import subBusinessDays from 'date-fns/subBusinessDays';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  BillableType,
  Employee,
  FormSelectOption,
  getLastBusinessDate,
  getUtcDate,
  isAbortError,
  longProductName
} from '~/app/shared';
import { ProductType } from '~/app/shared/product/product-type.enum';
import { ForecastRow, checkTwoCellsSameProduct } from '.';
import { ForecastCell } from './forecast-cell.model';
import { forecastService } from './forecast.service';
import { ForecastStoreType, useForecastStore } from './forecast.store';

const MAX_DAYS_YEAR = 365;
const MARGIN_NAVBARS_LEFT = 330;

export function useForecast() {
  const columnWidth = 50;
  const rowHeight = columnWidth;
  const firstYear = 2022;
  const currentYear = getUtcDate().getFullYear();
  const listOfYears: FormSelectOption[] = useMemo(
    () =>
      Array.from(Array(currentYear - firstYear + 3).keys())
        .map((x) => firstYear + x)
        .map((x) => ({ value: `${x}`, label: `${x}` })),
    [currentYear]
  );

  const [forecastState, forecastDispatch] = useForecastStore();
  const timelineRef = useRef(null);
  const getMoreCellsAbortController = useRef<AbortController>(new AbortController());
  const [scrolledFocusedDate, setScrolledFocusedDate] = useState(null);
  const [scrolledStartDate, setScrolledStartDate] = useState(null);
  const [initialScrollLeft, setInitialScrollLeft] = useState(0);
  const [selectedYear, setSelectedYear] = useState<number>();
  const [employees, setEmployees] = useState<Employee[]>();
  const [selectedTeamId, setSelectedTeamId] = useState<number>();
  const columnCount = useMemo(() => forecastState?.rows[0]?.timeline?.length, [forecastState?.rows]);
  const rowCount = useMemo(() => forecastState?.rows.length, [forecastState?.rows]);

  useEffect(function onUnmount() {
    return () => getMoreCellsAbortController.current.abort();
  }, []);

  const setInitialScroll = useCallback(async (scroll): Promise<void> => {
    const time = 500;
    return new Promise((resolve) => {
      setTimeout(() => setInitialScrollLeft(scroll), time);
      setTimeout(() => {
        // We only need the number for one tick
        setInitialScrollLeft(null);
        resolve();
      }, time * 1.5);
    });
  }, []);

  const loadScrolledFocusedDate = useCallback(
    (scrollLeft) => {
      const totalColumnsInWindow = Math.round(timelineRef.current?.clientWidth / columnWidth) - 1;
      const halfColumnsInWindow = Math.round(totalColumnsInWindow / 2);
      const scrolledColumn = Math.round(scrollLeft / columnWidth);
      const scrollColumns = Math.min(scrolledColumn + halfColumnsInWindow, MAX_DAYS_YEAR - 1);
      const scrolledStartDate = forecastState?.rows[0]?.timeline[scrolledColumn]?.date;

      setScrolledFocusedDate(forecastState?.rows[0]?.timeline[scrollColumns]?.date);
      setScrolledStartDate(scrolledStartDate);

      return scrolledStartDate;
    },
    [forecastState?.rows]
  );

  const getMoreCells = useCallback(
    async ({ date, add, allDays, selectedTeamId, employees }): Promise<void> => {
      if (!selectedTeamId) {
        return;
      }

      if (getMoreCellsAbortController.current) {
        getMoreCellsAbortController.current.abort();
      }

      getMoreCellsAbortController.current = new AbortController();

      try {
        const { data: newForecastCells } = await forecastService.getForecast({
          fromDate: date,
          daysToAdd: add,
          selectedTeamId,
          abortController: getMoreCellsAbortController.current
        });
        const processedForecastCells = forecastService.addEmployeesToForecast(date, newForecastCells, employees);
        const all = allDays || forecastState.allDays;
        const updatedForecastRows = forecastService.convertForecastToRows(processedForecastCells, all);

        if (!getMoreCellsAbortController.current.signal.aborted) {
          forecastDispatch({
            type: ForecastStoreType.SET_ROWS,
            payload: updatedForecastRows
          });

          forecastDispatch({
            type: ForecastStoreType.SET_VISIBLE_DATES,
            payload: {
              start: date,
              end: addBusinessDays(date, add)
            }
          });
        }

        const products = processedForecastCells.map((x) => x.product);

        const uniqueProducts = products
          .filter((a, i) => products.findIndex((s) => a?.id === s?.id) === i)
          .filter((x) => x?.name && !x?.isSpecialEffort)
          .sort((x, y) => longProductName(x).localeCompare(longProductName(y)));

        if (!getMoreCellsAbortController.current.signal.aborted) {
          forecastDispatch({
            type: ForecastStoreType.SET_PRODUCTS,
            payload: uniqueProducts
          });
        }
      } catch (error) {
        if (!isAbortError(error)) {
          console.error(error);
        }
      }
    },
    [forecastDispatch, forecastState.allDays]
  );

  const getInitialTimeline = useCallback(
    async (year) => {
      const date = getUtcDate(new Date(year, 0, 1, 10, 0));

      const firstDay = startOfYear(date);
      const lastDay = endOfYear(date);

      const allDays = eachDayOfInterval({ start: firstDay, end: lastDay })
        .map((x) => getUtcDate(x))
        .filter((x) => !isWeekend(x));

      forecastDispatch({
        type: ForecastStoreType.SET_DAYS,
        payload: allDays
      });

      return allDays;
    },
    [forecastDispatch]
  );

  const scrollToMonth = useCallback(
    async (date) => {
      const totalColumnsInWindow = Math.round(timelineRef.current?.clientWidth / columnWidth) - 1;
      const firstDay = startOfYear(date);
      const lastDay = endOfYear(date);
      let difference = differenceInBusinessDays(date, firstDay) - 1;
      const differenceToEnd = differenceInBusinessDays(lastDay, date) + 1;

      if (differenceToEnd < totalColumnsInWindow) {
        difference -= totalColumnsInWindow - differenceToEnd;
      }

      let scroll = columnWidth * (difference > 0 ? difference : 0);
      scroll = scroll === 0 ? 1 : scroll;

      await setInitialScroll(scroll);

      return scroll;
    },
    [setInitialScroll]
  );

  const getInitialCells = useCallback(
    async (teamId: number, year: number, employees: Employee[] = [], scrolledStartDate: Date = null) => {
      const yearDate = getUtcDate(new Date(year, 0, 1));
      const columns = Math.round((window.innerWidth - MARGIN_NAVBARS_LEFT) / columnWidth);
      const dateToScrollCurrentYear = scrolledStartDate
        ? scrolledStartDate
        : subBusinessDays(endOfYear(yearDate), columns);

      const date = getLastBusinessDate(dateToScrollCurrentYear);
      const scrollDate = dateToScrollCurrentYear;

      setSelectedTeamId(teamId);
      setSelectedYear(year);
      setEmployees(employees);

      const allDays = await getInitialTimeline(year);

      await getMoreCells({
        date,
        add: columns + 1,
        allDays,
        selectedTeamId: teamId,
        employees
      });

      await scrollToMonth(scrollDate);
    },
    [getInitialTimeline, getMoreCells, scrollToMonth]
  );

  const refreshVisibleCells = useCallback(
    async (date?: Date) => {
      getMoreCellsAbortController.current.abort();

      const daysToFetch = Math.round(window.innerWidth / columnWidth) + 1;
      const newDate = date || scrolledStartDate;

      await getMoreCells({
        date: newDate,
        add: daysToFetch + daysToFetch / 4,
        allDays: forecastState.allDays,
        selectedTeamId: selectedTeamId,
        employees
      });
    },
    [employees, forecastState.allDays, getMoreCells, scrolledStartDate, selectedTeamId]
  );

  const alterCell = useCallback(
    (
      row: ForecastRow,
      index: number,
      cell: ForecastCell,
      product = null,
      productId = null,
      specialEffort = 0,
      billableType = BillableType.None
    ) => {
      row.timeline[index] = {
        ...cell,
        product: {
          ...product
        },
        specialEffort,
        billableType
      };

      const newCell = {
        employeeId: cell.employee.id,
        productId: productId,
        date: cell.date,
        billableType,
        specialEffort
      };

      return newCell;
    },
    []
  );

  const generateCellsRequestFromRow = useCallback(
    (
      row: ForecastRow,
      cell: ForecastCell,
      extraX: number,
      rawX: number,
      isExpanding: boolean,
      numberOfColumns: number,
      isVertical = false
    ) => {
      const cellsRequest = [];

      let cellIndex = row?.timeline.findIndex((c) => compareAsc(c.date, cell.date) === 0);
      cellIndex += Math.round(extraX / columnWidth);
      cellIndex = !isExpanding && rawX > 0 ? cellIndex - 1 : cellIndex;
      cellIndex = !isExpanding && rawX < 0 ? cellIndex + 1 : cellIndex;

      if (numberOfColumns === 0 && !isVertical) {
        return [];
      }

      for (let i = isVertical ? 0 : 1; i <= Math.abs(numberOfColumns); i++) {
        const index = numberOfColumns > 0 ? cellIndex + i : cellIndex - i;

        if (index >= row.timeline.length) {
          continue;
        }

        const current = row.timeline[index];

        if (isWeekend(current.date)) {
          continue;
        }

        const specialEffortToAssign = isExpanding && !cell.product?.id ? cell?.specialEffort : null;
        const productToAssign = isExpanding ? cell.product : null;
        const productId = specialEffortToAssign || !isExpanding ? null : productToAssign?.id;
        const billableType = isExpanding ? cell.billableType : BillableType.None;
        const newCell = alterCell(row, index, current, productToAssign, productId, specialEffortToAssign, billableType);

        cellsRequest.push(newCell);
      }

      return cellsRequest;
    },
    [alterCell]
  );

  const getMultipleSelectedCells = useCallback(
    (cell: ForecastCell, rawX: number, extraX: number, rawY: number, extraY: number, isExpanding = false) => {
      const x = rawX + extraX;
      const y = rawY + extraY;
      const numberOfColumns = Math.round((x - extraX) / columnWidth);
      const numberOfRows = Math.round((y - extraY) / columnWidth);
      let cellsRequest = [];

      const rowIndex = forecastState.rows.findIndex((row) => row.employee.id === cell.employee.id);
      const row = forecastState.rows[rowIndex];

      // Horizontal axis
      cellsRequest = [...generateCellsRequestFromRow(row, cell, extraX, rawX, isExpanding, numberOfColumns)];

      // Vertical axis
      if (Math.abs(numberOfRows) === 0) {
        return cellsRequest;
      }

      let filteredRows = forecastState.rows.slice(rowIndex + 1, rowIndex + numberOfRows + 1);

      if (numberOfRows < 0) {
        const reversedRows = [...forecastState.rows].reverse();
        filteredRows = reversedRows.slice(
          reversedRows.length - rowIndex,
          reversedRows.length - rowIndex + Math.abs(numberOfRows)
        );
      }

      filteredRows = isExpanding ? filteredRows : filteredRows.filter((r) => r.employee.id === cell.employee.id);

      filteredRows.forEach((row) => {
        cellsRequest = [
          ...cellsRequest,
          ...generateCellsRequestFromRow(
            row,
            cell,
            isExpanding ? 0 : extraX,
            rawX,
            isExpanding,
            isExpanding ? Math.round(x / columnWidth) : numberOfColumns,
            true
          )
        ];
      });

      return cellsRequest;
    },
    [forecastState.rows, generateCellsRequestFromRow]
  );

  const getBlockSelectedCells = useCallback(
    (cell: ForecastCell, cellBeforeUpdate: ForecastCell) => {
      const rowIndex = forecastState.rows.findIndex((row) => row.employee.id === cell.employee.id);
      const row = forecastState.rows[rowIndex];

      const cellBeforeUpdateIndex = row.timeline.findIndex((x) => compareAsc(x.date, cellBeforeUpdate.date) === 0);
      const pastTimeline = row.timeline.slice(0, cellBeforeUpdateIndex + 1).reverse();
      const futureTimeline = row.timeline.slice(cellBeforeUpdateIndex);
      let initialDay: ForecastCell = null;
      let endDay = null;

      for (const [i, current] of pastTimeline.entries()) {
        if (!checkTwoCellsSameProduct(current, cellBeforeUpdate)) {
          initialDay = pastTimeline[i - 1];
          break;
        }
      }

      if (!initialDay) initialDay = pastTimeline[pastTimeline.length - 1];

      for (const [i, current] of futureTimeline.entries()) {
        if (!checkTwoCellsSameProduct(current, cellBeforeUpdate)) {
          endDay = futureTimeline[i - 1];
          break;
        }

        if (i === futureTimeline.length - 1) {
          endDay = futureTimeline[i];
        }
      }

      const specialEffort = cell?.specialEffort ?? null;

      initialDay.product = specialEffort
        ? { id: 0, type: ProductType.Regular, scope: null, status: 0, name: null, clientName: null, color: null }
        : cell.product;
      initialDay.billableType = cell?.billableType;
      initialDay.specialEffort = specialEffort;

      cellBeforeUpdate.product = initialDay.product;
      cellBeforeUpdate.billableType = initialDay.billableType;
      cellBeforeUpdate.specialEffort = initialDay.specialEffort;

      const newCell = {
        employeeId: initialDay.employee.id,
        productId: specialEffort ? null : initialDay.product?.id,
        date: initialDay.date,
        billableType: initialDay.billableType,
        specialEffort,
        comment: initialDay?.comment
      };

      const cells = getMultipleSelectedCells(
        initialDay,
        differenceInBusinessDays(endDay.date, initialDay.date) * columnWidth,
        0,
        0,
        0,
        true
      );

      return [newCell, ...cells];
    },
    [forecastState.rows, getMultipleSelectedCells]
  );

  return {
    getInitialCells,
    columnCount,
    rowCount,
    columnWidth,
    rowHeight,
    scrolledFocusedDate,
    refreshVisibleCells,
    loadScrolledFocusedDate,
    timelineRef,
    getMultipleSelectedCells,
    getBlockSelectedCells,
    initialScrollLeft,
    listOfYears,
    scrollToMonth
  } as const;
}
