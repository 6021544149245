import { t } from '~/app/app.i18n';
import { formatDateForServer, getDateFormated, toastService } from '~/app/shared';
import { ProductType } from '~/app/shared/product/product-type.enum';
import { ForecastCell, ForecastError, ForecastErrorType } from '.';

export function checkTwoCellsSameProduct(cella: ForecastCell, cellb: ForecastCell) {
  // If empty we are not interested
  if (!cella?.specialEffort && !cella?.product?.id) {
    return false;
  }

  const areSame = cella?.specialEffort
    ? cella?.specialEffort === cellb?.specialEffort && cella?.haveBilling === cellb?.haveBilling
    : cella?.product?.id && cella?.product?.id === cellb?.product?.id && cella?.haveBilling === cellb?.haveBilling;

  if (areSame) {
    return cella?.product?.type !== ProductType.Regular ? areSame : cella?.billableType === cellb?.billableType;
  }

  return false;
}

export function isDateSprintMark(date: Date, sprintMarks: string[] = []): boolean {
  return sprintMarks.includes(formatDateForServer(date));
}

export function showForecastError(result: ForecastCell[], customTitle?: string) {
  const errorItems = result?.filter((x) => !x.isSuccess);

  if (errorItems.length < 1) {
    return;
  }

  const errors = errorItems.map(
    (errorItem) => `${errorItem.employee?.name} - ${getDateFormated(errorItem.date)} - ${errorItem?.errorMessage}`
  );

  if (errorItems.every((x) => x.errorType === ForecastErrorType.Warning)) {
    const title = customTitle || t('errors.genericWarning');
    const warningTemplate = ForecastError({ title, errors });
    toastService.warningMessage(warningTemplate);

    return;
  }

  const title = customTitle || t('errors.genericError');
  const errorTemplate = ForecastError({ title, errors });
  toastService.errorMessage(errorTemplate);
}
