import { Dispatch, SetStateAction } from 'react';
import { HttpFetchResponse, isAbortError, transformIntegerToStringFormatSync } from '~/app/shared';
import { FormSelectOption } from '../form';
import { Team } from './team.model';

export function longTeamName(team: Team): string {
  if (!team?.unit) {
    return getTeamName(team.number);
  }

  if (!team.unit.businessLine) {
    return `${team.unit.name} - ${getTeamName(team.number)}`;
  }

  return `${team.unit.businessLine?.name} - ${team.unit.name} - ${getTeamName(team.number)}`;
}

export function getTeamName(input: number): string {
  return transformIntegerToStringFormatSync(input, { preffix: 'Team ' });
}

export async function teamsPromiseFilter(
  teams: Team[],
  getAll: (abortController: AbortController) => Promise<HttpFetchResponse<Team[]>>,
  setTeams: Dispatch<SetStateAction<Team[]>>,
  inputValue: string,
  abortController: AbortController
) {
  const text = inputValue.toLowerCase();
  let teamsFiltered = teams?.filter((x) => longTeamName(x).toLowerCase().includes(text));

  if (!inputValue) {
    try {
      const { data } = await getAll(abortController);
      teamsFiltered = data;
      setTeams(data);
    } catch (error) {
      if (!isAbortError(error)) {
        console.error(error);
      }
    }
  }

  if (!teamsFiltered || !teamsFiltered.length) {
    return [];
  }

  return teamsFiltered
    .map((team) => ({ value: team.id, label: longTeamName(team) }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export async function teamsByUnitPromiseFilter(
  teams: Team[],
  getByUnitId: (unitId: number) => Promise<Team[]>,
  setTeams: Dispatch<SetStateAction<Team[]>>,
  inputValue: string,
  unitId?: number
) {
  const text = inputValue.toLowerCase();
  let teamsFiltered = teams?.filter((x) => longTeamName(x).toLowerCase().includes(text));

  if (!inputValue || !unitId) {
    teamsFiltered = await getByUnitId(unitId);
    setTeams(teamsFiltered);
  }

  return teamsFiltered
    ?.slice(0, 10)
    .map((x) => ({ value: x.id, label: longTeamName(x) }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export async function teamsByUnitsPromiseFilter(
  teams: Team[],
  getByUnitId: (unitId: number, abortController) => Promise<HttpFetchResponse<Team[]>>,
  setTeams: Dispatch<SetStateAction<Team[]>>,
  inputValue: string,
  unitsIds: number[],
  abortController: AbortController
): Promise<FormSelectOption[]> {
  const text = inputValue?.toLowerCase();
  let teamsFiltered = teams?.filter((x) => longTeamName(x).toLowerCase().includes(text));

  if (!inputValue || unitsIds.length < 1) {
    try {
      const promises = unitsIds.map((x) => getByUnitId(x, abortController));
      const results = await Promise.all(promises);
      teamsFiltered = results.map((result) => result.data).flat();

      setTeams(teamsFiltered);
    } catch (error) {
      if (!isAbortError(error)) {
        console.error(error);
      }
    }
  }

  return teamsFiltered
    .map((x) => ({ value: x.id, label: longTeamName(x) }))
    .sort((a, b) => a.label.localeCompare(b.label));
}
