export enum __OldRole {
  None = '1',
  ServicesUser = '2',
  ServicesManager = '3',
  ServicesDirector = '4',
  SalesUser = '6',
  Admin = '5', // Not in factorial, not used anymore
  Viewer = '7', // Not in factorial, not used anymore
  AccountController = '8'
}
