import { createStore, ReducerType, useStore } from 'react-hookstore';
import { GenericPayload } from '~/app/core';

const name = 'ROOT/HEADERCELL';

enum Type {
  SET_IS_TOGGLEING = 'ROOT/HEADERCELL/SET_IS_TOGGLEING',
  RESET_TOGGLE = 'ROOT/HEADERCELL/RESET_TOGGLE'
}

type Payload = GenericPayload<Type>;

interface State {
  isToggeling: boolean;
}

const state: State = {
  isToggeling: false
};

const reducers: ReducerType<State, Payload> = function (state: State, { type, payload }) {
  switch (type) {
    case Type.SET_IS_TOGGLEING:
      return {
        ...state,
        isToggeling: payload
      };

    case Type.RESET_TOGGLE:
      return {
        ...state,
        isToggeling: false
      };
    default:
      return { ...state };
  }
};

const store = createStore<State>(name, state, reducers);

export const HeaderCellStoreType = Type;
export type HeaderCellStoreState = State;
export const useHeaderCellStore = () => useStore<State>(store);
