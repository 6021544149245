import {
  ContentTypes,
  ExternalEffortCreate,
  ExternalEffortDelete,
  ExternalEffortEdit,
  HttpFetchResponse,
  HttpMethod,
  ProblemDetails,
  ProblemDetailsErrorsExtension
} from '../shared';
import { ExternalEmployee } from '../shared/external-effort/external-effort.model';

export class ExternalEffortService {
  public async get(
    year: number,
    month: number,
    productId: number,
    employeeId: number,
    abortController: AbortController
  ): Promise<HttpFetchResponse<ExternalEmployee>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/externaleffort/year/${year}/month/${month}/product/${productId}/employee/${employeeId}`;

    const response = await fetch(url, { signal: abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async create(
    externalEffort: ExternalEffortCreate
  ): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/externalEffort';

    const body = {
      year: externalEffort.year,
      month: externalEffort.month,
      effort: externalEffort.effort,
      productId: externalEffort.productId,
      employeeId: externalEffort.employeeId
    };

    const response = await fetch(url, {
      method: HttpMethod.POST,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async edit(
    externalEffort: ExternalEffortEdit
  ): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/externalEffort';

    const body = {
      id: externalEffort.id,
      effort: externalEffort.effort
    };

    const response = await fetch(url, {
      method: HttpMethod.PATCH,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async delete(
    externalEfforts: ExternalEffortDelete
  ): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/externalEffort';

    const body = {
      ids: externalEfforts.ids
    };

    const response = await fetch(url, {
      method: HttpMethod.DELETE,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async countExternalEffortAfterDate(
    endDate: Date,
    employeeId: number | string
  ): Promise<HttpFetchResponse<number>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/externalEffort/afterDate`;

    const body = {
      endDate: endDate,
      employeeId: employeeId
    };

    const response = await fetch(url, {
      method: HttpMethod.POST,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async deleteAfterDate(
    employeeId: string | number,
    endDate: Date
  ): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/externaleffort/afterDate`;

    const body = {
      endDate: endDate,
      employeeId: employeeId
    };

    const response = await fetch(url, {
      method: HttpMethod.DELETE,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }
}

export const externalEffortService = new ExternalEffortService();
