import { Vector2 } from '@use-gesture/react';
import { createStore, ReducerType, useStore } from 'react-hookstore';
import { GenericPayload } from '~/app/core';
import { ForecastCell } from './forecast-cell.model';

const name = 'ROOT/FORECASTCELL';

enum Type {
  SET_CURRENTLY_DRAGGING = 'ROOT/FORECASTCELL/RESET',
  SET_START_DRAG_COORDS = 'ROOT/FORECASTCELL/SET_START_DRAG_COORDS',
  SET_DRAG_COORDS = 'ROOT/FORECASTCELL/SET_DRAG_COORDS',
  SET_DRAG_COLOR = 'ROOT/FORECASTCELL/SET_DRAG_COLOR',
  RESET_DRAG = 'ROOT/FORECASTCELL/RESET_DRAG'
}

type Payload = GenericPayload<Type>;

interface State {
  currentlyDragging: ForecastCell;
  startDragCoords: Vector2;
  dragCoords: Vector2;
  dragColor: string;
}

const state: State = {
  currentlyDragging: null,
  startDragCoords: null,
  dragCoords: null,
  dragColor: null
};

const reducers: ReducerType<State, Payload> = function (state: State, { type, payload }) {
  switch (type) {
    case Type.SET_CURRENTLY_DRAGGING:
      return {
        ...state,
        currentlyDragging: payload.cell,
        startDragCoords: payload.startDragCoords,
        dragColor: payload.dragColor
      };
    case Type.SET_START_DRAG_COORDS:
      return {
        ...state,
        startDragCoords: payload
      };
    case Type.SET_DRAG_COORDS:
      return {
        ...state,
        dragCoords: payload.dragCoords
      };
    case Type.SET_DRAG_COLOR:
      return {
        ...state,
        dragColor: payload
      };

    case Type.RESET_DRAG:
      return {
        currentlyDragging: null,
        startDragCoords: null,
        dragCoords: null,
        dragColor: null
      };
    default:
      return { ...state };
  }
};

const store = createStore<State>(name, state, reducers);

export const ForecastCellStoreType = Type;
export type ForecastCellStoreState = State;
export const useForecastCellStore = () => useStore<State>(store);
