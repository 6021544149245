import { addDays, differenceInBusinessDays, format, isToday, isWeekend } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import {
  AuthAction,
  AuthSubject,
  DynamicStyleClass,
  ProductStatus,
  getProductCellBulletStyles,
  useCan
} from '~/app/shared';
import { ForecastCell, ForecastProductCellSelector, ProductSelectorValueProps, useForecastStore } from '.';
import { RenderBodyCellDrag } from './RenderBodyCellDrag';
import { useForecastCell } from './forecast-cell.hook';
import { useForecastCellStore } from './forecast-cell.store';
import { isDateSprintMark } from './forecast.helper';

export function RenderBodyCell({
  timeline,
  columnIndex,
  rowIndex,
  style,
  rowHeight,
  columnWidth,
  addProductAfterDragCell,
  initialProducts,
  sprintMarks,
  addNewAssignedCell,
  addNewAssignedBlock,
  scrolledFocusedDate,
  isLoading
}) {
  const { can } = useCan();
  const [forecastState] = useForecastStore();
  const [forecastCellState] = useForecastCellStore();
  const [selectedCell, setSelectedCell] = useState<ForecastCell>();
  const [clickedColumn, setClickedColumn] = useState<number>();
  const { isPreviousTheSame, maxNextTheSame, commentsList } = useForecastCell(timeline, columnIndex);

  const cell: ForecastCell = timeline[columnIndex];
  const nextCell: ForecastCell = timeline[columnIndex + 1];
  const previousCell: ForecastCell = timeline[columnIndex - 1];
  const endDate = new Date(cell?.employee?.endDate);
  const hiringDate = new Date(cell?.employee?.hiringDate);
  const isFinished = `${cell?.product?.status}` === ProductStatus.Finished;

  const { product, color, ...productBulletStyles } = getProductCellBulletStyles(cell);

  const canEditForecastFromPast = useMemo(() => can(AuthAction.Manage, AuthSubject.ForecastFromPast), [can]);

  const columnSpacing = useMemo(
    () => (clickedColumn ? clickedColumn * columnWidth : columnWidth),
    [clickedColumn, columnWidth]
  );

  function onClickCell(event: React.MouseEvent<HTMLDivElement, MouseEvent>, cell: ForecastCell) {
    event.stopPropagation();
    event.preventDefault();

    if (clickedColumn || forecastCellState.currentlyDragging || (cell.haveBilling && !canEditForecastFromPast)) {
      return;
    }

    const clickedCellColumn = Math.ceil(
      (event?.clientX - event?.currentTarget?.getBoundingClientRect()?.x) / columnWidth
    );

    let selectedCell = cell;
    if (clickedCellColumn > 1) {
      selectedCell = timeline[columnIndex + clickedCellColumn - 1];
    }

    setSelectedCell(selectedCell);
    setClickedColumn(clickedCellColumn);
  }

  async function onClickEmptyCell(cell: ForecastCell) {
    if (forecastCellState.currentlyDragging || (cell.haveBilling && !canEditForecastFromPast)) {
      return;
    }

    setSelectedCell(cell);
  }

  const onChangeProductSelectorValue = useCallback(
    (props: ProductSelectorValueProps, isAll = false) => {
      const { product, specialEffort, comment, billableType } = props;

      const selectedCellCopy: ForecastCell = JSON.parse(JSON.stringify(selectedCell));
      selectedCellCopy.product = product;
      selectedCellCopy.billableType = billableType;
      selectedCellCopy.date = new Date(selectedCellCopy.date);
      selectedCellCopy.specialEffort = specialEffort;
      selectedCellCopy.comment = comment;

      if (isAll) {
        addNewAssignedBlock(selectedCellCopy, selectedCell);
        return;
      }

      addNewAssignedCell(selectedCellCopy);
    },
    [addNewAssignedBlock, addNewAssignedCell, selectedCell]
  );

  const onCloseProductSelector = useCallback(() => {
    setTimeout(() => {
      setSelectedCell(null);
      setClickedColumn(null);
    }, 10);
  }, []);

  if (columnIndex < 0 || timeline === undefined || timeline?.length <= 1) {
    return <div></div>;
  }

  const customStyles = {
    width: style.width,
    height: style.height
  };

  const basicClasses: DynamicStyleClass = new DynamicStyleClass().fromRecord({
    forecast__cell: true,
    'forecast__cell--today': isToday(cell?.date),
    'forecast__cell--even': columnIndex % 2 === 0,
    'forecast__cell--first': isDateSprintMark(cell?.date, sprintMarks)
  });

  if (forecastState.visibleEndDay < hiringDate) {
    return null;
  }

  // If is weekend or endDate or hiring arrived we show the weekend cell
  if (
    isWeekend(cell?.date) ||
    differenceInBusinessDays(endDate, cell?.date) < 0 ||
    differenceInBusinessDays(cell?.date, hiringDate) < 0
  ) {
    return <div className={basicClasses.clone().add('forecast__cell--not-allowed').toString()} style={style}></div>;
  }

  const wasYesterdayWeekend = isWeekend(addDays(cell?.date, -1));
  const isWeekendTomorrow = isWeekend(addDays(cell?.date, 1));

  // If there is no product we show the empty cell
  if ((!cell?.product && !cell.specialEffort) || forecastState.visibleEndDay < hiringDate) {
    return (
      <div
        className={basicClasses
          .clone()
          .fromRecord({
            'forecast__cell--weekend': wasYesterdayWeekend,
            'forecast__cell--preweekend': isWeekendTomorrow,
            'is-open':
              selectedCell && !isFinished && (!cell.haveBilling || (cell.haveBilling && canEditForecastFromPast))
          })
          .toString()}
        onClick={() => onClickEmptyCell(cell)}
        style={style}
      >
        {selectedCell && (!cell.haveBilling || (cell.haveBilling && canEditForecastFromPast)) && (
          <ForecastProductCellSelector
            cell={selectedCell}
            isNew={true}
            hideBlockActions={true}
            onClose={onCloseProductSelector}
            spacing={columnSpacing}
            onChange={onChangeProductSelectorValue}
            scrolledFocusedDate={scrolledFocusedDate}
            columnWidth={columnWidth}
          />
        )}
      </div>
    );
  }

  // When there is a product we show the cell with a product or nothing if this cell belongs to a group of cells with product (draggables)
  return (
    <>
      <div
        className={`${basicClasses} ${
          selectedCell && !isFinished && (!cell.haveBilling || (cell.haveBilling && canEditForecastFromPast))
            ? 'is-open'
            : ''
        }  ${wasYesterdayWeekend ? 'forecast__cell--weekend' : ''}  ${
          isWeekendTomorrow ? 'forecast__cell--preweekend' : ''
        }`}
        onClick={(e) => onClickCell(e, cell)}
        style={{ ...style, '--spacing': `${columnSpacing - columnWidth}px` }}
        data-test={format(cell?.date, 'dd')}
      >
        {selectedCell &&
          !isFinished &&
          !isLoading &&
          (!cell.haveBilling || (cell.haveBilling && canEditForecastFromPast)) && (
            <ForecastProductCellSelector
              cell={selectedCell}
              hideBlockActions={true}
              onClose={onCloseProductSelector}
              onChange={onChangeProductSelectorValue}
              spacing={columnSpacing}
              scrolledFocusedDate={scrolledFocusedDate}
              columnWidth={columnWidth}
            />
          )}
        {isPreviousTheSame ? (
          <></>
        ) : (
          <RenderBodyCellDrag
            color={color}
            rowHeight={rowHeight}
            columnWidth={columnWidth}
            addProductAfterDragCell={addProductAfterDragCell}
            cell={cell}
            customStyles={customStyles}
            productBulletStyles={productBulletStyles}
            maxNextTheSame={maxNextTheSame}
            isFinished={isFinished}
            productStatus={product.status}
            title={
              product.isSpecialEffort
                ? product.name
                : `${product.name} - ${product.projectName} - ${product.clientName}`
            }
            subtitle={product?.clientName}
            isSpecial={product.isSpecialEffort}
            isLocked={cell.haveBilling && !canEditForecastFromPast}
            forecastCommentList={commentsList}
          />
        )}
      </div>
    </>
  );
}
