import { useDrag } from '@use-gesture/react';
import { useCallback, useMemo, useState } from 'react';
import { Gesture } from '~/app/core';
import { IsBillable, Show, Tooltip } from '~/app/shared';
import { ProductType } from '~/app/shared/product/product-type.enum';
import { RefreshStoreType, useRefreshStore } from '~/store';
import { RenderBodyCellDragInfo } from './RenderBodyCellDragInfo';
import { CellTooltipInformation } from './RenderBodyCellTooltip';
import { ForecastCellCommentMark } from './forecast-cell-comment-mark';
import { CELL_GUTTER, ForecastCell } from './forecast-cell.model';
import { ForecastCellStoreType, useForecastCellStore } from './forecast-cell.store';
import './forecast-circle.css';
import { ForecastStoreType, useForecastStore } from './forecast.store';

export type RenderBodyCellDragProps = {
  rowHeight: number;
  columnWidth: number;
  addProductAfterDragCell;
  cell: ForecastCell;
  color;
  customStyles;
  maxNextTheSame;
  productBulletStyles;
  isFinished;
  title;
  subtitle;
  productStatus;
  isSpecial;
  isLocked;
  forecastCommentList;
};

export function RenderBodyCellDrag(props: RenderBodyCellDragProps) {
  const {
    rowHeight,
    columnWidth,
    addProductAfterDragCell,
    cell,
    color,
    customStyles,
    maxNextTheSame,
    productBulletStyles,
    isFinished,
    title,
    subtitle,
    productStatus,
    isSpecial,
    isLocked,
    forecastCommentList
  } = props;

  const [, refreshDispatch] = useRefreshStore();
  const [forecastCellState, forecastCellDispatch] = useForecastCellStore();
  const [, forecastDispatch] = useForecastStore();
  const [currentX, setCurrentX] = useState(0);
  const [currentY, setCurrentY] = useState(0);
  const [isDraggingSinglePart, setIsDraggingSinglePart] = useState(false);
  const [isDraggingEndPart, setIsDraggingEndPart] = useState(false);
  const [isDraggingStartPart, setIsDraggingStartPart] = useState(false);
  const [currentComment, setCurrentComment] = useState<string>(cell?.comment);

  const moreColumnsToDraw = maxNextTheSame ?? 0;
  const productColor = productBulletStyles.backgroundColor || '#dfe6ec';

  const absY = Math.abs(currentY);
  const height = absY + rowHeight - rowHeight * CELL_GUTTER;

  const styles = useMemo(() => {
    const isExpanding = isDraggingStartPart ? currentX < 0 : currentX >= 0;

    const absX = Math.abs(currentX);
    let width = absX + columnWidth - columnWidth * CELL_GUTTER + moreColumnsToDraw * columnWidth;
    let left = (isDraggingStartPart || isDraggingSinglePart) && currentX < 0 ? currentX : 0;
    const top = isDraggingSinglePart && currentY < 0 ? currentY : 0;

    if (!isExpanding && isDraggingStartPart) {
      width = width - absX * 2;
      left = left + absX;
    }

    if (!isExpanding && isDraggingEndPart) {
      width = width - absX * 2;
    }

    return {
      ...customStyles,
      left: (columnWidth * CELL_GUTTER) / 2,
      top: (rowHeight * CELL_GUTTER) / 2,
      '--border': productColor,
      '--borderStyle':
        !IsBillable(cell.billableType) && cell?.product?.type === ProductType.Regular ? 'dashed' : 'solid',
      '--width': `${width}px`,
      '--columnWidth': `${columnWidth}px`,
      '--endWidth': `${width > 2 * columnWidth ? width / 2 : 0}px`,
      '--height': `${height}px`,
      '--left': `${left}px`,
      '--top': `${top}px`,
      '--transition': isDraggingEndPart ? 'width 0s' : 'left 0s',
      '--z': isDraggingSinglePart || isDraggingEndPart || isDraggingStartPart ? 100 : 1
    };
  }, [
    isDraggingStartPart,
    currentX,
    columnWidth,
    moreColumnsToDraw,
    isDraggingSinglePart,
    currentY,
    isDraggingEndPart,
    customStyles,
    rowHeight,
    productColor,
    cell.billableType,
    cell?.product?.type,
    height
  ]);

  const afterAddProductToCell = useCallback(
    () =>
      setTimeout(() => {
        refreshDispatch({ type: RefreshStoreType.SET_REFRESH });
        forecastCellDispatch({ type: ForecastCellStoreType.RESET_DRAG, payload: null });
        forecastDispatch({ type: ForecastStoreType.SET_DRAGGING, payload: false });
      }, 10),
    [forecastCellDispatch, forecastDispatch, refreshDispatch]
  );

  const checkAndUpdateCoordsOnDragCells = useCallback(
    (active: boolean, x: number, cx: number, extraX: number, y: number, cy: number, extraY: number) => {
      const xInFirstCell = (cx % columnWidth) - x;
      const yInFirstCell = ((cy + rowHeight / 2) % rowHeight) - y;

      let numberOfColumns = -1 * (Math.ceil(xInFirstCell / columnWidth) - 1);
      const numberOfRows = -1 * (Math.ceil(yInFirstCell / rowHeight) - 1);

      // At this moment we decide to force cell to be dragged only horizontal or vertical not both
      if (numberOfRows !== 0 && numberOfColumns !== 0) {
        numberOfColumns = 0;
      }

      if (!forecastCellState.startDragCoords && x === 0 && y === 0) {
        return false;
      }

      if (!forecastCellState.startDragCoords) {
        forecastCellDispatch({
          type: ForecastCellStoreType.SET_CURRENTLY_DRAGGING,
          payload: {
            cell,
            dragColor: color.foreground,
            startDragCoords: [cx, cy]
          }
        });

        forecastDispatch({
          type: ForecastStoreType.SET_DRAGGING,
          payload: true
        });
      }

      if (active) {
        const currentX = columnWidth * numberOfColumns;
        const currentY = rowHeight * numberOfRows;

        forecastCellDispatch({
          type: ForecastCellStoreType.SET_DRAG_COORDS,
          payload: {
            dragCoords: [currentX, currentY]
          }
        });

        setCurrentX(currentX);
        setCurrentY(currentY);

        return false;
      }

      const isInADifferentCellX = numberOfColumns !== 0;
      const isInADifferentCellY = numberOfRows !== 0;

      const isInADifferentCell = isInADifferentCellX || isInADifferentCellY;

      if (!isInADifferentCell) {
        afterAddProductToCell();
      }

      setTimeout(() => {
        setIsDraggingEndPart(false);
        setIsDraggingStartPart(false);
        setIsDraggingSinglePart(false);
        setCurrentX(0);
        setCurrentY(0);
      }, 10);

      return isInADifferentCell;
    },
    [
      afterAddProductToCell,
      cell,
      color.foreground,
      columnWidth,
      forecastCellDispatch,
      forecastCellState.startDragCoords,
      forecastDispatch,
      rowHeight
    ]
  );

  const checkAndUpdateCoordsOnDragCellsToIncrease = useCallback(
    (active: boolean, x: number, cx: number, extraX = 0, y = 0, cy = 0, extraY = 0) =>
      checkAndUpdateCoordsOnDragCells(active, x, cx, extraX, y, cy, extraY),
    [checkAndUpdateCoordsOnDragCells]
  );

  const checkAndUpdateCoordsOnDragCellsToDecrease = useCallback(
    (active: boolean, x: number, cx: number, extraX = 0, y = 0, cy = 0, extraY = 0) =>
      checkAndUpdateCoordsOnDragCells(active, x, cx, extraX, y, cy, extraY),
    [checkAndUpdateCoordsOnDragCells]
  );

  const onDragSingleCell: Gesture = useDrag((event) => {
    const {
      args: [cell],
      active,
      movement: [x, y],
      xy: [cx, cy]
    } = event;

    if (isLocked || isFinished) {
      return;
    }

    if (active) {
      setIsDraggingSinglePart(true);
    }

    let shouldUpdate = false;

    if (checkAndUpdateCoordsOnDragCellsToIncrease(active, x, cx, 0, y, cy, 0)) {
      shouldUpdate = true;
    }

    if (!shouldUpdate) {
      return;
    }

    addProductAfterDragCell(cell, currentX, 0, currentY, 0, true);
    afterAddProductToCell();
  });

  const onDragStartCell: Gesture = useDrag((event) => {
    const {
      args: [cell],
      active,
      movement: [x],
      xy: [cx]
    } = event;

    if (isLocked || isFinished) {
      return;
    }

    if (active) {
      setIsDraggingStartPart(true);
    }

    let shouldUpdate = false;
    const isExpanding = x < 0;
    const maxX = !isExpanding && x > moreColumnsToDraw * columnWidth ? moreColumnsToDraw * columnWidth : x;

    if (isExpanding && checkAndUpdateCoordsOnDragCellsToIncrease(active, x, cx)) {
      shouldUpdate = true;
    }

    if (!isExpanding && checkAndUpdateCoordsOnDragCellsToDecrease(active, maxX, cx)) {
      shouldUpdate = true;
    }

    if (!shouldUpdate) {
      return;
    }

    addProductAfterDragCell(cell, currentX, 0, 0, 0, isExpanding);
    afterAddProductToCell();
  });

  const onDragEndCell: Gesture = useDrag((event) => {
    const {
      args: [cell],
      active,
      movement: [x, y],
      xy: [cx, cy]
    } = event;

    if (isLocked || isFinished) {
      return;
    }

    if (active) {
      setIsDraggingEndPart(true);
    }

    let shouldUpdate = false;
    const extraX = moreColumnsToDraw * columnWidth;
    const isExpanding = x >= 0;
    const maxX = !isExpanding && Math.abs(x) > maxNextTheSame * columnWidth ? -maxNextTheSame * columnWidth : x;

    if (isExpanding && checkAndUpdateCoordsOnDragCellsToIncrease(active, x, cx, extraX)) {
      shouldUpdate = true;
    }

    if (!isExpanding && checkAndUpdateCoordsOnDragCellsToDecrease(active, maxX, cx, extraX)) {
      shouldUpdate = true;
    }

    if (!shouldUpdate) {
      return;
    }

    addProductAfterDragCell(cell, currentX, extraX, 0, 0, isExpanding);
    afterAddProductToCell();
  });

  const onHoverCommentMark = (comment: string) => {
    if (comment === currentComment) {
      return;
    }
    setCurrentComment(comment);
  };

  const totalCells = moreColumnsToDraw + 1;

  const getCellStateClass = () => {
    if (isLocked) {
      return 'forecast-circle--locked';
    }

    return isFinished ? 'forecast-circle--not-allowed' : '';
  };

  const getCellWidth = (currentIndex: number) => {
    if (currentIndex === 0 || currentIndex === forecastCommentList.length - 1) {
      return rowHeight - styles.left;
    }
    return rowHeight;
  };

  const getCellPadding = () => {
    const cellWidth = rowHeight - styles.left;
    const space = `${cellWidth - height}px`;
    return space;
  };

  const commentStyles = (i) => {
    return {
      height: styles['--height'],
      top: '100%',
      width: getCellWidth(i),
      paddingRight: i === 0 ? getCellPadding() : 0,
      '--lineColor': isSpecial ? '#000000' : productColor
    };
  };

  const commentStyle = {
    '--height': styles['--height'],
    left: styles.left
  };

  return (
    <Tooltip
      isTimeline
      arrow={false}
      disabled={!title}
      maxWidth={'none'}
      delay={[500, 100]}
      content={
        <CellTooltipInformation
          cellData={cell}
          comment={currentComment}
          isSpecial={isSpecial}
          productStatus={productStatus}
          subtitle={subtitle}
          title={title}
          maxNextTheSame={maxNextTheSame ?? 0}
          borderColor={color.background}
        />
      }
    >
      <div
        className="forecast-circle-container"
        style={{
          height: styles.height,
          width: maxNextTheSame * columnWidth + columnWidth
        }}
      >
        {title && (
          <>
            <RenderBodyCellDragInfo
              title={title}
              cell={cell}
              subtitle={subtitle}
              productStatus={productStatus}
              totalCells={totalCells}
              styles={styles}
              productBulletStyles={productBulletStyles}
              isSpecial={isSpecial}
            />
            <Show when={!!cell.product}>
              <ForecastCellCommentMark
                styles={commentStyle}
                onHoverMark={onHoverCommentMark}
                commentsList={forecastCommentList}
                commentStyles={commentStyles}
                commentStateClass={getCellStateClass()}
                isSpecial
              />
            </Show>

            {moreColumnsToDraw ? (
              <>
                <span
                  {...onDragStartCell(cell)}
                  onMouseEnter={() => {
                    onHoverCommentMark(forecastCommentList[0]);
                  }}
                  className={`forecast-circle forecast-circle--start forecast-circle--draggable ${getCellStateClass()}`}
                  style={styles}
                ></span>
                <span
                  {...onDragEndCell(cell)}
                  onMouseEnter={() => {
                    onHoverCommentMark(forecastCommentList[forecastCommentList.length - 1]);
                  }}
                  className={`forecast-circle forecast-circle--end forecast-circle--draggable ${getCellStateClass()}`}
                  style={{
                    ...styles,
                    opacity: isDraggingEndPart || isDraggingStartPart || isDraggingSinglePart ? 0 : 1
                  }}
                ></span>
              </>
            ) : (
              <span
                {...onDragSingleCell(cell)}
                className={`forecast-circle forecast-circle--start forecast-circle--draggable ${getCellStateClass()}`}
                style={styles}
              ></span>
            )}
          </>
        )}
      </div>
    </Tooltip>
  );
}
