import { useEffect } from 'react';

export const PageName = {
  EMPLOYEES: 'Capacity Tool - Employees',
  FORECAST: 'Capacity Tool - Forecast',
  REPORTS: 'Capacity Tool - Global Reports',
  DETAIL: 'Capacity Tool - Product Detail',
  PROJECTS: 'Capacity Tool - Projects',
  PROJECT: 'Capacity Tool - Project Detail',
  PRODUCTS: 'Capacity Tool - Products',
  PROPOSALS: 'Capacity Tool - Proposals',
  PROPOSAL: 'Capacity Tool - Proposal Detail',
  TEAMS: 'Capacity Tool - Teams',
  BONIFICATIONS: 'Capacity Tool - Bonifications Report',
  BILLING: 'Capacity Tool - Billing',
  BILLINGUNIT: 'Capacity Tool - Unit Billing'
};

export function useChangePageTitle(pageName: string) {
  useEffect(() => {
    document.title = pageName;
  }, [pageName]);
}
