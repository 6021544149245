import image from '~/assets/images/PC_Symbol.svg';
import './header.css';

interface HeaderProps {
  title: string;
}

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  return (
    <div className="header__container">
      <div className="header__left">
        <img className="header__image" src={image} alt="logo_of_plain_concepts" />

        <div className="header__title ">{props.title}</div>
      </div>

      <div className="header__right">{props.children}</div>
    </div>
  );
};

export default Header;
