import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

export function ModalConfirmation({
  showModal,
  confirmModal,
  hideModal,
  titleModal = 'modal.action-confirmation',
  bodyModal = 'modal.information',
  additionalInfo = null
}) {
  const [t] = useTranslation();

  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t(titleModal)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{additionalInfo ? <p>{additionalInfo}</p> : <p>{t(bodyModal)}</p>}</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          {t('modal.cancel')}
        </Button>

        <Button variant="primary" onClick={() => confirmModal()}>
          {t('modal.accept')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
