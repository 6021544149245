import { useCallback, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useTranslation } from 'react-i18next';
import { LayoutGridAdd } from 'tabler-icons-react';
import { Tooltip } from '~/app/shared';
import { ForecastBulkAddForm } from './ForecastBulkAddForm';
import './forecast-bulk-add.css';

interface ForecastBulkAddProps {
  onFormSubmit: () => void;
}

export function ForecastBulkAdd(props: ForecastBulkAddProps) {
  const [t] = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const { onFormSubmit } = props;

  const onHidePanel = useCallback(() => setIsVisible(false), []);
  const onShowPanel = () => setIsVisible(true);

  const onSubmitPanel = useCallback(() => {
    onFormSubmit();
    onHidePanel();
  }, [onFormSubmit, onHidePanel]);

  return (
    <>
      <Tooltip title={t('forecast.actions.bulk-add')}>
        <button className="forecast-bulk-add__action" onClick={onShowPanel}>
          <LayoutGridAdd className="forecast__icon" />
        </button>
      </Tooltip>
      <Offcanvas
        placement="end"
        backdrop
        scroll
        responsive="lg"
        show={isVisible}
        onHide={onHidePanel}
        {...props}
        className="forecast-bulk-add"
      >
        <Offcanvas.Header className="forecast-bulk-add__header">
          <Offcanvas.Title className="forecast-bulk-add__title">{t('forecast.bulk-add.form-title')}</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="forecast-bulk-add__body">
          {isVisible && <ForecastBulkAddForm onHidePanel={onHidePanel} onSubmitPanel={onSubmitPanel} />}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
