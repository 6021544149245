import { createStore, ReducerType, useStore } from 'react-hookstore';
import { GenericPayload } from '~/app/core';

const name = 'ROOT/TEAMS';

enum Type {
  SET_SELECTED = 'ROOT/TEAMS/SET_SELECTED',
  SET_FILTER = 'ROOT/TEAMS/SET_FILTER'
}

type Payload = GenericPayload<Type>;

interface State {
  team: number;
  filter: string;
}

const state: State = {
  team: null,
  filter: ''
};

const reducers: ReducerType<State, Payload> = function (state: State, { type, payload }) {
  switch (type) {
    case Type.SET_SELECTED:
      return {
        ...state,
        team: payload
      };
    case Type.SET_FILTER:
      return {
        ...state,
        filter: payload
      };
    default:
      return { ...state };
  }
};

const store = createStore<State>(name, state, reducers);

export const TeamsStoreType = Type;
export type TeamsStoreState = State;
export const useTeamsStore = () => useStore<State>(store);
