import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Show } from '~/app/shared';
import { ProjectStatus, ProjectType } from '~/app/shared/projects';
import useOutsideClick from '../hook/useOutsideClick';
import './FilterPopup.css';
import { FilterState } from './FilterState';

interface FilterPopupProps {
  column: ColumnDef<any, any>;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  onFilterChange: (columnId: string, filterValue: any) => void;
  currentFilters: FilterState;
}

export const FilterPopup: React.FC<FilterPopupProps> = ({
  column,
  onClose,
  anchorEl,
  onFilterChange,
  currentFilters
}) => {
  const [t] = useTranslation();
  const [popupStyle, setPopupStyle] = useState({ top: 0, left: 0 });
  const [selectedFilters, setSelectedFilters] = useState<Set<any>>(() => {
    const currentFilter = currentFilters.find(
      (f) => f.id === (column as { accessorKey?: string }).accessorKey || column.id
    );
    return new Set(currentFilter ? currentFilter.value : []);
  });
  const popupRef = useRef<HTMLDivElement>(null);

  useOutsideClick(popupRef, onClose, { current: anchorEl });
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const updatePosition = () => {
      if (anchorEl) {
        const rect = anchorEl.getBoundingClientRect();
        setPopupStyle({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX
        });
        setIsReady(true);
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, [anchorEl]);

  const handleCheckboxChange = (value: any) => {
    setSelectedFilters((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(value)) {
        newSet.delete(value);
      } else {
        newSet.add(value);
      }
      return newSet;
    });
  };

  const handleApplyFilter = () => {
    const columnId = (column as { accessorKey?: string }).accessorKey || column.id;
    onFilterChange(columnId, Array.from(selectedFilters));
    onClose();
  };

  if (!anchorEl) return null;

  return ReactDOM.createPortal(
    <Show when={isReady}>
      <div ref={popupRef} className="filter-popup" style={popupStyle}>
        {column.meta === 'project' ? (
          <div className="filter-options">
            {Object.entries(ProjectType).map(([key, value]) => (
              <label key={value}>
                <input
                  type="checkbox"
                  checked={selectedFilters.has(value as ProjectType)}
                  onChange={() => handleCheckboxChange(value as ProjectType)}
                />
                {key}
              </label>
            ))}
          </div>
        ) : column.meta === 'status' ? (
          <div className="filter-options">
            {Object.entries(ProjectStatus).map(([key, value]) => (
              <label key={value}>
                <input
                  type="checkbox"
                  checked={selectedFilters.has(value as ProjectStatus)}
                  onChange={() => handleCheckboxChange(value as ProjectStatus)}
                />
                {key}
              </label>
            ))}
          </div>
        ) : column.meta === 'rat' ? (
          <div className="filter-options">
            <label key={0}>
              <input type="checkbox" checked={selectedFilters.has(0)} onChange={() => handleCheckboxChange(0)} />
              OK
            </label>
            <label key={1}>
              <input type="checkbox" checked={selectedFilters.has(1)} onChange={() => handleCheckboxChange(1)} />
              KO
            </label>
            <label key={2}>
              <input type="checkbox" checked={selectedFilters.has(2)} onChange={() => handleCheckboxChange(2)} />-
            </label>
          </div>
        ) : null}

        <div>
          <button onClick={onClose} className="mx-2 btn btn-outline-danger">
            {t('common.close')}
          </button>
          <button onClick={handleApplyFilter} className="mx-2 btn btn-primary">
            {t('common.apply')}
          </button>
        </div>
      </div>
    </Show>,
    document.body
  );
};
