import { createStore, ReducerType, useStore } from 'react-hookstore';
import { GenericPayload } from '~/app/core';

const name = 'ROOT/EMPLOYEES';

enum Type {
  SET_SELECTED = 'ROOT/EMPLOYEES/SET_SELECTED',
  SET_FILTER = 'ROOT/EMPLOYEES/SET_FILTER'
}

type Payload = GenericPayload<Type>;

interface State {
  employee: number;
  filter: string;
}

const state: State = {
  employee: null,
  filter: ''
};

const reducers: ReducerType<State, Payload> = function (state: State, { type, payload }) {
  switch (type) {
    case Type.SET_SELECTED:
      return {
        ...state,
        employee: payload
      };
    case Type.SET_FILTER:
      return {
        ...state,
        filter: payload
      };
    default:
      return { ...state };
  }
};

const store = createStore<State>(name, state, reducers);

export const EmployeesStoreType = Type;
export type EmployeesStoreState = State;
export const useEmployeesStore = () => useStore<State>(store);
