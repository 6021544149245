import {
  addDays,
  compareDesc,
  differenceInBusinessDays,
  format,
  getMonth,
  isFirstDayOfMonth,
  isThisWeek,
  isToday,
  isWeekend,
  startOfMonth
} from 'date-fns';
import { i18nMonth } from '~/app/app.i18n';
import { DynamicStyleClass } from '~/app/shared';
import { isDateSprintMark } from './forecast.helper';
import { useForecastStore } from './forecast.store';
import { useHeaderCellStore } from './header-cell.store';

export function RenderHeaderCell({ scrolledStartDate, columnIndex, rowIndex, style, sprintMarks, onToggleSprintMark }) {
  const [forecastState] = useForecastStore();
  const [headerCellState] = useHeaderCellStore();

  const timeline = forecastState?.rows[rowIndex]?.timeline;

  if (columnIndex < 0 || timeline?.length <= 1) {
    return <div></div>;
  }

  if (timeline) {
    const cell = timeline[columnIndex];
    const date = cell?.date;
    const startMonth = startOfMonth(date);

    const showSprintMark = isDateSprintMark(date, sprintMarks);

    const showMonth =
      compareDesc(scrolledStartDate, date) === 0 ||
      isFirstDayOfMonth(date) ||
      (isWeekend(startMonth) && !~differenceInBusinessDays(startMonth, date));

    const wasYesterdayWeekend = isWeekend(addDays(cell?.date, -1));

    function onClickCell(event: React.MouseEvent<HTMLDivElement, MouseEvent>, date: Date) {
      event.stopPropagation();
      event.preventDefault();

      if (headerCellState.isToggeling) {
        return;
      }

      onToggleSprintMark(date, showSprintMark);
    }

    const containerClasses: string = new DynamicStyleClass()
      .fromRecord({
        forecast__date: true,
        'forecast__date--today': isToday(date),
        'forecast__date--week': isThisWeek(date),
        'forecast__date--weekend': wasYesterdayWeekend,
        'forecast__date-month--first-day': showSprintMark
      })
      .toString();

    const childMonthClasses: string = new DynamicStyleClass()
      .fromRecord({
        'forecast__date-month': true,
        'forecast__date-month--first-month': showMonth,
        'forecast__date-month--first-day': !showMonth && !wasYesterdayWeekend && showSprintMark
      })
      .toString();

    return (
      <div style={style} onClick={(e) => onClickCell(e, cell.date)} className={containerClasses}>
        <span className={childMonthClasses}>{i18nMonth(getMonth(date)).slice(0, 3)}</span>

        <span className="forecast__date-value">{format(date, 'dd')}</span>
      </div>
    );
  }

  return <div></div>;
}
