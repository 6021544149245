import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { employeesService } from '~/app/employees/shared';
import { AuthStoreType, isAbortError, updateAbilities, useAuthStore } from '~/app/shared';

enum AbilitiesStatus {
  Idle,
  Initialized
}

export function useEmployeeSession(): boolean {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [_, authDispatch] = useAuthStore();

  const [abilitiesStatus, setAbilitiesStatus] = useState<AbilitiesStatus>(AbilitiesStatus.Idle);

  useEffect(
    function saveAccountMetadata(): void {
      const [account]: AccountInfo[] = instance.getAllAccounts();

      if (account === undefined) return;

      authDispatch({ type: AuthStoreType.SET_NAME, payload: account.name });
      authDispatch({ type: AuthStoreType.SET_USER_NAME, payload: account.username });
      authDispatch({ type: AuthStoreType.SET_ROLES, payload: account.idTokenClaims.roles });
    },
    [instance, authDispatch]
  );

  useEffect(
    function fetchCurrentEmployeeDataAndSaveInMemory() {
      if (abilitiesStatus === AbilitiesStatus.Initialized) return;

      const abortController = new AbortController();

      employeesService
        .getCurrent({ abortController })
        .then((response) => {
          if (!response.data) return navigate('/auth/forbidden');

          const employeeRole = response.data.employeeRole;

          authDispatch({ type: AuthStoreType.SET_ID, payload: response.data.id });
          authDispatch({ type: AuthStoreType.SET_EMPLOYEE_UNITID, payload: response.data.unit?.id });
          authDispatch({ type: AuthStoreType.SET_EMPLOYEE_ROLE, payload: employeeRole });
          authDispatch({ type: AuthStoreType.SET_EMPLOYEE_ROLE_NAME, payload: employeeRole.name });

          updateAbilities(employeeRole);
          setAbilitiesStatus(AbilitiesStatus.Initialized);

          if (employeeRole.scopes?.some((x) => x.scopeName === 'NotAssigned') && employeeRole.scopes?.length === 1) {
            return navigate('/auth/not-allowed');
          }

          if (!employeeRole.scopes?.some((x) => x.abilities?.length > 0)) {
            return navigate('/auth/forbidden');
          }

          if (response.data.isMaintenance) {
            authDispatch({ type: AuthStoreType.SET_MAINTENANCE, payload: true });
            return navigate('/auth/maintenance');
          }
        })
        .catch((error) => {
          if (!isAbortError(error)) {
            console.error(error);
          }
        });

      return () => abortController.abort();
    },
    [abilitiesStatus, authDispatch, navigate]
  );

  return useMemo(() => abilitiesStatus === AbilitiesStatus.Initialized, [abilitiesStatus]);
}
