type ForecastErrorProps = {
  title: string;
  errors: string[];
};

export function ForecastError(props: ForecastErrorProps) {
  const { title, errors } = props;
  return (
    <div>
      {title && <strong>{title} </strong>}
      {errors && errors.map((x, i) => <p key={i}>{x}</p>)}
    </div>
  );
}
