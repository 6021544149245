import { Tooltip } from '..';

interface TableCellDateProps {
  text: string;
  children?: React.ReactNode;
}

export function TableCellDate(props: TableCellDateProps) {
  const { text, children } = props;

  return (
    <Tooltip title={text}>
      <span style={{ color: '#606060', fontWeight: 500, fontSize: '13px' }}>
        {text} {children}
      </span>
    </Tooltip>
  );
}
